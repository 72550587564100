<template>
  <transition
    :enter-active-class="`animated ${toast.config.animation.enter}`"
    :leave-active-class="`animated ${toast.config.animation.exit}`"
    @enter="enter"
    @leave="leave"
    @before-enter="beforeEnter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
  >
    <div
      v-show="opacity"
      :class="['snotify-' + toast.config.type]"
      :style="{
        opacity,
        'max-height': maxHeight + 'px',
        '-webkit-animation-duration': toast.config.animation.time + 'ms',
        'animation-duration': toast.config.animation.time + 'ms',
        '-webkit-transition': toast.config.animation.time + 'ms',
        transition: toast.config.animation.time + 'ms'
      }"
      class="snotifyToast"
      @click="onClick"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <div
        v-if="toast.config.showProgressBar"
        class="snotifyToast__progressBar"
      >
        <span
          :style="{'width': progress + '%'}"
          class="snotifyToast__progressBar__percentage"
        />
      </div>

      <div
        v-if="!toast.config.html"
        class="snotifyToast__inner"
      >
        <div
          v-if="toast.title"
          class="snotifyToast__title"
        >
          {{ toast.title | truncate(toast.config.titleMaxLength) }}
        </div>

        <div class="snotifyToast__body">
          {{ toast.body | truncate(toast.config.bodyMaxLength) }}
        </div>

        <snotify-prompt
          v-if="toast.config.type === promptType"
          :placeholder="toast.config.placeholder"
          @valueChanged="promptValueChanged"
        />

        <snotify-icon
          v-if="!toast.config.icon"
          :type="toast.config.type"
          class="snotify-icon"
        />

        <v-icon
          v-else
          class="snotify-icon"
          dark
          style="opacity: 0.75; font-size: 48px;"
        >
          {{ toast.config.icon }}
        </v-icon>
      </div>

      <div
        v-else
        class="snotifyToast__inner"
        v-html="toast.config.html"
      />

      <snotify-button
        v-if="toast.config.buttons"
        :id="toast.id"
        :buttons="toast.config.buttons"
        :value="toast.config.type === promptType ? value : null"
      />
    </div>
  </transition>
</template>

<script>
import SnotifyIcon    from './SnotifyIcon.vue'
import SnotifyPrompt  from './Prompt.vue'
import SnotifyButton  from './Button.vue'
import SnotifyService from '../SnotifyService'
import SnotifyType    from '../enums/SnotifyType'
import SnotifyAction  from '../enums/SnotifyAction'

export default {
  components: {
    SnotifyIcon,
    SnotifyPrompt,
    SnotifyButton
  },
  props: {
    toastData: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      toast     : this.toastData,
      opacity   : 0,
      interval  : null,
      progress  : 0,
      value     : 0,
      promptType: SnotifyType.PROMPT,
      maxHeight : SnotifyService.options.maxHeight
    }
  },
  created () {
    SnotifyService.$on('toastChanged', (toast) => {
      if (this.toast.id === toast.id) {
        this.initToast(toast)
      }
    })
    SnotifyService.$on('remove', (id) => {
      if (this.toast.id === id) {
        this.opacity = 0
      }
    })
    this.initToast()
  },
  mounted: function () {
    this.$nextTick(function () {
      SnotifyService.$emit(SnotifyAction.mounted, this.toast, this.value)
    })
  },
  beforeDestroy () {
    SnotifyService.$emit(SnotifyAction.beforeDestroy, this.toast, this.value)
  },
  destroyed () {
    SnotifyService.$emit(SnotifyAction.destroyed, this.toast, this.value)
  },
  methods: {
    promptValueChanged (value) {
      this.value = value
      SnotifyService.$emit(SnotifyAction.onInput, this.toast, this.value)
    },
    /**
     * Initialize base toast config
     * @param [toast] {SnotifyToast}
     */
    initToast (toast) {
      if (toast) {
        if (this.toast.config.type !== toast.config.type) {
          clearInterval(this.interval)
        }
        this.toast = toast
      }
      if (this.toast.config.timeout > 0) {
        this.startTimeout(0)
      } else {
        this.toast.config.showProgressBar = false
        this.toast.config.pauseOnHover = false
      }
      this.opacity = 1
    },
    /**
     * Start progress bar
     * @param currentProgress {Number}
     */
    startTimeout (currentProgress) {
      const refreshRate = 10
      this.progress = currentProgress
      const step = refreshRate / this.toast.config.timeout * 100
      this.interval = setInterval(() => {
        this.progress += step
        if (this.progress >= 100) {
          this.opacity = 0
        }
      }, refreshRate)
    },
    beforeEnter () {
      SnotifyService.$emit(SnotifyAction.beforeShow, this.toast, this.value)
    },
    enter (el, done) {
      setTimeout(() => done(), this.toast.config.animation.time)
    },
    afterEnter () {
      SnotifyService.$emit(SnotifyAction.shown, this.toast, this.value)
    },
    beforeLeave () {
      clearInterval(this.interval)
      SnotifyService.$emit(SnotifyAction.beforeHide, this.toast, this.value)
    },
    leave (el, done) {
      setTimeout(() => {
        SnotifyService.$emit(SnotifyAction.hidden, this.toast, this.value)
        this.maxHeight = 0
        setTimeout(() => {
          SnotifyService.remove(this.toast.id)
          done()
        }, this.toast.config.animation.time)
      }, this.toast.config.animation.time)
    },
    onClick () {
      SnotifyService.$emit(SnotifyAction.onClick, this.toast, this.value)
      if (this.toast.config.closeOnClick) {
        this.opacity = 0
      }
    },
    onMouseEnter () {
      SnotifyService.$emit(SnotifyAction.onHoverEnter, this.toast, this.value)
      if (this.toast.config.pauseOnHover) {
        clearInterval(this.interval)
      }
    },
    onMouseLeave () {
      if (this.toast.config.pauseOnHover && this.type !== SnotifyType.PROMPT) {
        this.startTimeout(this.progress)
      }
      SnotifyService.$emit(SnotifyAction.onHoverLeave, this.toast, this.value)
    }
  }
}
</script>
