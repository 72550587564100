import BaseResource from '@/lib/data/resource/BaseResource'

export default class UserResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.CompanyId = payload.CompanyId ?? null
    this.CompanyName = payload.CompanyName ?? ''
    this.FirstName = payload.FirstName ?? ''
    this.LastName = payload.LastName ?? ''
    this.FullName = payload.FullName ?? ''
    this.DisplayName = payload.DisplayName ?? ''
    this.Username = payload.Username ?? ''
    this.Password = payload.Password ?? ''
    this.Phone = payload.Phone ?? ''
    this.Email = payload.Email ?? ''
    this.RoleId = payload.RoleId ?? null
    this.RoleName = payload.RoleName ?? ''
    this.RoleColor = payload.RoleColor ?? 'gray'
    this.Permissions = payload.Permissions ?? []
    this.Locale = payload.Locale ?? null
    this.SingleLogin = payload.SingleLogin ?? false
    this.Active = payload.Active ?? false
    this.Admin = payload.Admin ?? false
    this.TwoFA = payload.TwoFA ?? false
    this.ForceChangePassword = payload.ForceChangePassword ?? false
    this.CreatedAt = payload.CreatedAt ?? ''
    this.Notes = payload.Notes ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
