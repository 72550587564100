import BaseResource      from '@/lib/data/resource/BaseResource'
import { collect }       from '@/lib/utils/array'
import CommitteeResource from '@/api/resources/election/CommitteeResource'

export default class DashboardResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Name = payload.Name ?? ''
    this.Description = payload.Description ?? ''
    this.StartedAt = payload.StartedAt ?? ''
    this.EndedAt = payload.EndedAt ?? ''
    this.Email = payload.Email ?? true
    this.Sms = payload.Sms ?? false
    this.Committee = collect(payload.Committee ?? [], CommitteeResource)
    this.Categories = payload.Categories ?? []
    this.ActionStartedAt = payload.ActionStartedAt ?? ''
    this.ActionEndedAt = payload.ActionEndedAt ?? ''
    this.ActionVotesAt = payload.ActionVotesAt ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
