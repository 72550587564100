import PermissionEnum from '@/api/enums/PermissionEnum'

export default {
  name   : 'permissionColors',
  methods: {
    getPermissionColor (permission) {
      switch (permission.toLocaleUpperCase(this.$i18n.locale)) {
      case PermissionEnum.API:
        return 'purple'
      case PermissionEnum.ACCESS:
        return 'green'
      case PermissionEnum.MANAGE:
        return 'blue'
      case PermissionEnum.ADD:
        return 'amber darken-3'
      case PermissionEnum.DELETE:
        return 'red'
      default:
        return 'grey'
      }
    }
  }
}
