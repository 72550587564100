export default {

  Title: 'Εκλογές',

  Table: {
    Button: {
      New: 'Νέες Εκλογές'
    },

    Headers: {
      Id             : '#',
      Name           : 'ΟΝΟΜΑ',
      Company        : 'ΕΝΩΣΗ',
      StartedAt      : 'ΑΠΟ',
      EndedAt        : 'ΕΩΣ',
      Email          : 'EMAIL',
      Sms            : 'SMS',
      ActionStartedAt: 'ΕΝΑΡΞΗ',
      ActionEndedAt  : 'ΛΗΞΗ',
      ActionVotesAt  : 'ΑΠΟΤΕΛΕΣΜΑΤΑ',
      CreatedAt      : 'ΔΗΜΙΟΥΡΓΙΑ',
      Actions        : ''
    },

    Filter: {
      Active: {
        Type       : 'v-btn-toggle',
        Label      : 'Εκλογές',
        PlaceHolder: 'Όλες τις Εκλογές',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 'null',
            Name : 'Όλα'
          },
          {
            Value: 1,
            Name : 'Ενεργές'
          },
          {
            Value: 0,
            Name : 'Ανενεργές'
          }
        ]
      },

      CompanyId: {
        Type       : 'v-select',
        Label      : 'Ενώσεις',
        PlaceHolder: 'Όλες οι Ενώσεις',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      }
    }
  },

  Confirm: {
    Delete: {
      Title   : 'Διαγραφή Εκλογών {Id}',
      SubTitle: 'Επιβεβαίωση Διαγραφής Εκλογών',
      Body    : 'Είστε σίγουροι ότι θέλετε προχωρήσετε στην οριστική διαγραφή των εκλογών {Id};'
    },
    Notification: {
      Title   : 'Αποστολή {Type} Ψηφοφόρων',
      SubTitle: 'Επιβεβαίωση Αποστολής {Type} Ψηφοφόρων',
      Body    : 'Είστε σίγουροι ότι θέλετε στείλετε {Type} σε όλους τους ψηφοφόρους των εκλογών: <strong>{Name}</strong> της ένωσης <strong>{Company}</strong>;'
    },
    NotifyMember: {
      Title   : 'Αποστολή {Type} Ψηφοφόρου',
      SubTitle: 'Επιβεβαίωση Αποστολής {Type} Ψηφοφόρου',
      Body    : 'Είστε σίγουροι ότι θέλετε στείλετε {Type} στον ψηφοφόρο: <strong>{Name}</strong> με αριθμό ταυτότητας <strong>{Uid}</strong> των εκλογών: <strong>{ElectionName}</strong> της ένωσης <strong>{Company}</strong>;'
    }
  },

  Form: {
    Title : 'Εκλογών',
    Fields: {
      CompanyId: {
        Label: 'Ένωση',
        Hint : ''
      },
      Name: {
        Label: 'Όνομα',
        Hint : ''
      },
      Description: {
        Label: 'Περιγραφή',
        Hint : ''
      },
      StartedAt: {
        Label: 'Ημ. Από',
        Hint : ''
      },
      StartedTimeAt: {
        Label: 'Ώρα Από',
        Hint : ''
      },
      EndedAt: {
        Label: 'Ημ. Έως',
        Hint : ''
      },
      EndedTimeAt: {
        Label: 'Ώρα Έως',
        Hint : ''
      },
      Email: {
        Label: 'Email',
        Hint : ''
      },
      Sms: {
        Label: 'SMS',
        Hint : ''
      },
      Committee: {
        Label: 'Εφορευτική Επιτροπή',
        Hint : '',
        Title: 'Τίτλος',
        Name : 'Όνομα',
        Email: 'Email',
        Phone: 'Τηλέφωνο'
      },
      Categories: {
        Label: 'Ψηφοδέλτιο'
      },
      Candidates: {
        Label: 'Υποψήφιοι'
      },
      MaxVotes: {
        Label: 'Μέγιστοι Ψήφοι'
      },
      NotesWebsite: {
        Label: 'Ανακοίνωση Website'
      },
      NotesEmail: {
        Label: 'Ανακοίνωση Email'
      }
    }
  }
}
