import BaseResource      from '@/lib/data/resource/BaseResource'
import { collect }       from '@/lib/utils/array'
import CommitteeResource from '@/api/resources/election/CommitteeResource'

export default class ElectionResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.CompanyId = payload.CompanyId ?? null
    this.Company = payload.Company ?? null
    this.Name = payload.Name ?? ''
    this.Description = payload.Description ?? ''
    this.StartedAt = payload.StartedAt ?? ''
    this.StartedAtFmt = payload.StartedAtFmt ?? ''
    this.StartedTimeAt = payload.StartedTimeAt ?? ''
    this.EndedAt = payload.EndedAt ?? ''
    this.EndedAtFmt = payload.EndedAtFmt ?? ''
    this.EndedTimeAt = payload.EndedTimeAt ?? ''
    this.Email = payload.Email ?? true
    this.Sms = payload.Sms ?? false
    this.Pdf = payload.Pdf ?? ''
    this.VotersPdf = payload.VotersPdf ?? ''
    this.Committee = collect(payload.Committee ?? [], CommitteeResource)
    this.CommitteeEmail = false
    this.CommitteeSms = false
    this.Categories = payload.Categories ?? []
    this.Notes = payload.Notes || (!payload.Id ? '<p><strong>Συμπληρώστε τον αριθμό της Ταυτότητας σας, για να μπορέσετε να συνεχίσετε.</strong></p><p><em>Για τυχόν πρόβλημα επικοινωνήστε με τον Πρόεδρο της Εφορευτικής Επιτροπής στο τηλέφωνο 6977971071.</em></p>' : '')
    this.NotesEmail = payload.NotesEmail || (!payload.Id ? '<p>Αγαπητή συναδέλφισσα - Αγαπητέ συνάδελφε</p><p><br>Προσκαλείστε να συμμετάσχετε στην ψηφοφορία των θεσμικών οργάνων της Ένωσης Στρατιωτικών Περιφέρειας Ανατολικής Μακεδονίας και Θράκης την Κυριακή 31 Μαρτίου 2024.</p><p></p><p><strong>ΕΚΛΟΓΕΣ Ε.Σ.ΠΕ.Α,Μ/Θ</strong><br><br>Ημερομηνία έναρξης ψηφοφορίας 31 Μαρτίου 2024 και ώρα 09:00 και λήξης 31 Μαρτίου 2024 και ώρα 15:00.<br><br>Για να υποβάλετε την ψήφο σας, ακολουθήστε τον παρακάτω σύνδεσμο ο οποίος θα ενεργοποιηθεί με την έναρξη της ψηφοφορίας.</p>' : '')
    this.ActionStartedAt = payload.ActionStartedAt ?? ''
    this.ActionEndedAt = payload.ActionEndedAt ?? ''
    this.ActionVotesAt = payload.ActionVotesAt ?? ''
    this.CreatedAt = payload.CreatedAt ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
