import ElectionMembers from './ElectionMembers'

import ElectionCategories from './ElectionCategories'

import Elections from './Elections'

import Members     from './Members'
import Common      from './Common'
import Route       from './Route'
import Dashboard   from './Dashboard'
import Companies   from './Companies'
import Users       from './Users'
import Roles       from './Roles'
import Permissions from './Permissions'
import Settings    from './Settings'

export default {
  ElectionMembers   : ElectionMembers,
  ElectionCategories: ElectionCategories,
  Elections         : Elections,
  Members           : Members,
  Common            : Common,
  Route             : Route,
  Dashboard         : Dashboard,
  Companies         : Companies,
  Users             : Users,
  Roles             : Roles,
  Permissions       : Permissions,
  Settings          : Settings
}
