import BaseValidator      from '@/lib/data/validator/BaseValidator'

export default class ElectionCategoryValidator extends BaseValidator {
  validatorRules = {
    Id: {
      required: false,
      type    : BaseValidator.Type.Number
    },
    Name: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2,
      maxLen  : 255
    },
    Description: {
      required: false,
      type    : BaseValidator.Type.String,
      minLen  : 2,
      maxLen  : 255
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
