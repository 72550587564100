<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="fill-height">
          <v-card-title class="pa-2 d-block">
            <v-toolbar
              :color="!$vuetify.theme.dark ? 'grey lighten-3' : ''"
              extension-height="60"
              flat
            >
              <template #default>
                <v-btn
                  v-if="userCanAdd"
                  :fab="$vuetify.breakpoint.mobile"
                  :small="$vuetify.breakpoint.mobile"
                  class="px-2 mr-1"
                  color="primary"
                  @click="createItem()"
                >
                  <v-icon :class="{'mr-1': !$vuetify.breakpoint.mobile}">
                    mdi-plus
                  </v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">
                    {{ $t(`${$options.name}.Table.Button.New`).toLocaleUpperCase($i18n.locale) }}
                  </template>
                </v-btn>

                <v-btn
                  v-if="userCanAdd"
                  :fab="$vuetify.breakpoint.mobile"
                  :small="$vuetify.breakpoint.mobile"
                  class="px-2 mr-1 white--text"
                  color="blue-grey"
                  @click="importExcel"
                >
                  <v-icon :class="{'mr-1': !$vuetify.breakpoint.mobile}">
                    mdi-import
                  </v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">
                    {{ $t(`Common.Button.Import`).toLocaleUpperCase($i18n.locale) }} EXCEL
                  </template>
                </v-btn>

                <v-btn
                  v-if="userCanAccess"
                  :fab="$vuetify.breakpoint.mobile"
                  :small="$vuetify.breakpoint.mobile"
                  class="px-2 mr-1 white--text"
                  color="blue-grey"
                  @click="exportExcel"
                >
                  <v-icon :class="{'mr-1': !$vuetify.breakpoint.mobile}">
                    mdi-export
                  </v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">
                    {{ $t(`Common.Button.Export`).toLocaleUpperCase($i18n.locale) }} EXCEL
                  </template>
                </v-btn>

                <div class="flex-grow-1" />

                <data-table-search
                  v-if="dataTable.search.enabled"
                  v-model="dataTable.search.terms"
                />

                <data-table-filter
                  v-model="dataTable"
                  :filters="filterData"
                />
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            :headers="dataTable.headers"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.Active`]="{item}">
              <v-switch
                v-model="item.Active"
                :disabled="!userCanManage"
                :readonly="!userCanManage"
                class="ma-0 pt-0 d-inline-flex"
                color="success"
                hide-details
                inset
                @change="item.Update()"
              />
            </template>

            <template
              v-if="userCanDelete || userCanManage || userCanAccess"
              #[`item.Actions`]="{item}"
            >
              <v-speed-dial
                v-model="item.fab"
                direction="left"
                right
              >
                <template #activator>
                  <v-btn
                    v-model="item.fab"
                    color="blue darken-2"
                    dark
                    depressed
                    fab
                    x-small
                  >
                    <v-icon v-if="item.fab">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-menu
                    </v-icon>
                  </v-btn>
                </template>

                <v-tooltip
                  v-if="userCanDelete"
                  :open-delay="650"
                  bottom
                  color="red"
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userCanDelete"
                      :disabled="item.loadingDelete"
                      :loading="item.loadingDelete"
                      class="ml-1 white--text"
                      color="red"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="deleteItem(item)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Common.Button.Delete')" />
                </v-tooltip>

                <v-tooltip
                  v-if="userCanAccess"
                  :open-delay="650"
                  bottom
                  color="info"
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userCanAccess"
                      :disabled="item.loadingView"
                      :loading="item.loadingView"
                      class="ml-1 white--text"
                      color="info"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="viewItem(item)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Common.Button.View')" />
                </v-tooltip>

                <v-tooltip
                  v-if="userCanManage"
                  :open-delay="650"
                  bottom
                  color="grey"
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userCanManage"
                      :disabled="item.loadingEdit"
                      :loading="item.loadingEdit"
                      class="ml-1 white--text"
                      color="grey"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="editItem(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Common.Button.Edit')" />
                </v-tooltip>
              </v-speed-dial>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <member-edit-dialog
      v-if="userCanAccess || userCanAdd || userCanManage"
      :companies="filterData.CompanyId.Items"
      :item="selectedItem"
      :item-view-mode="itemViewMode"
      :visible.sync="editDialogVisible"
      @form:save:success="getData"
    />

    <member-import-dialog :visible.sync="importDialogVisible" />
  </v-container>
</template>

<script>
import DataTable from '@/mixins/table/dataTable'
import DataTableActions from '@/mixins/table/dataTableActions'
import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import MemberModel from '@/api/models/member/MemberModel'
import { themeUtils } from '@/lib/utils'
import PermissionEnum from '@/api/enums/PermissionEnum'
import API from '@/api/Api'
import { collect } from '@/lib/utils/array'
import CompanyModel from '@/api/models/company/CompanyModel'
import MemberImportDialog from '@/theme/default/views/member/MemberImportDialog.vue'

const DataTableFilter = () => themeUtils.importThemeComponent('components/common/dataTable/DataTableFilter')
const DataTableSearch = () => themeUtils.importThemeComponent('components/common/dataTable/DataTableSearch')
const MemberEditDialog = () => themeUtils.importThemeComponent('views/member/MemberEditDialog')

export default {
  name      : 'Members',
  components: {
    MemberImportDialog,
    DataTableSearch,
    DataTableFilter,
    MemberEditDialog
  },
  directives: {},
  mixins    : [i18nRouteMeta, DataTable, DataTableActions],
  props     : {},
  dataStore : {},
  enums     : {},
  dataModel : MemberModel,
  data () {
    return {
      importDialogVisible: false,
      dataTable          : {
        customFilter: {},
        filter      : {
          Active   : null,
          CompanyId: null
        },
        options: {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        headers: [
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.Id`) ? this.$t(`${this.$options.name}.Table.Headers.Id`) : '#',
            value   : 'Id',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.CompanyName`) ? this.$t(`${this.$options.name}.Table.Headers.CompanyName`) : 'CompanyName',
            value   : 'CompanyName',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.Uid`) ? this.$t(`${this.$options.name}.Table.Headers.Uid`) : 'Uid',
            value   : 'Uid',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.Corps`) ? this.$t(`${this.$options.name}.Table.Headers.Corps`) : 'Corps',
            value   : 'Corps',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.FirstName`) ? this.$t(`${this.$options.name}.Table.Headers.FirstName`) : 'FirstName',
            value   : 'FirstName',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.LastName`) ? this.$t(`${this.$options.name}.Table.Headers.LastName`) : 'LastName',
            value   : 'LastName',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.FathersName`) ? this.$t(`${this.$options.name}.Table.Headers.FathersName`) : 'FathersName',
            value   : 'FathersName',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.Phone`) ? this.$t(`${this.$options.name}.Table.Headers.Phone`) : 'Phone',
            value   : 'Phone',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.Email`) ? this.$t(`${this.$options.name}.Table.Headers.Email`) : 'Email',
            value   : 'Email',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.Active`) ? this.$t(`${this.$options.name}.Table.Headers.Active`) : 'Active',
            value   : 'Active',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.CreatedAt`) ? this.$t(`${this.$options.name}.Table.Headers.CreatedAt`) : 'CreatedAt',
            value   : 'CreatedAt',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.Actions`) ? this.$t(`${this.$options.name}.Table.Headers.Actions`) : '',
            value   : 'Actions',
            align   : 'right',
            sortable: false,
            width   : 40
          }
        ]
      }
    }
  },
  computed: {
    exportUrl () {
      return `${process.env.VUE_APP_API_BASE_URL}${process.env.VUE_APP_API_PREFIX}members/export`
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    exportExcel () {
      const params = []
      const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/export-members/`

      const url = `${baseUrl}${this.authorizationToken}/?${params.join('&')}`
      window.open(url, '_blank')
    },

    importExcel () {
      this.importDialogVisible = true
    },

    getFilterData () {
      this.getCompanies()
    },

    getCompanies () {
      if (!this.userCan('Companies', PermissionEnum.API)) return

      API.Resource.Company.DropDownList()
        .then(response => {
          if (API.isResponseSuccess(response)) {
            const data = API.responseData(response)
            this.filterData.CompanyId.Items = collect(data?.items || [], CompanyModel)
          }
        })
        .catch(e => {
        })
        .finally(() => {
        })
    }
  }
}
</script>

<style scoped>

</style>
