export default {
  ElectionMembers: {
    Title: '@:ElectionMembers.Title',
    Icon : 'mdi-account-group',
    Color: 'primary',
    Meta : {
      Title: '@:Route.ElectionMembers.Title',
      Desc : '',
      Img  : ''
    }
  },

  ElectionCategories: {
    Title: '@:ElectionCategories.Title',
    Icon : 'mdi-ballot-outline',
    Color: 'primary',
    Meta : {
      Title: '@:Route.ElectionCategories.Title',
      Desc : '',
      Img  : ''
    }
  },

  Elections: {
    Title: '@:Elections.Title',
    Icon : 'mdi-vote',
    Color: 'primary',
    Meta : {
      Title: '@:Route.Elections.Title',
      Desc : '',
      Img  : ''
    }
  },

  Members: {
    Title: '@:Members.Title',
    Icon : 'mdi-account-group',
    Color: 'primary',
    Meta : {
      Title: '@:Route.Members.Title',
      Desc : '',
      Img  : ''
    }
  },

  Login: {
    Title: '@:Users.Login.Title',
    Icon : 'mdi-logout',
    Color: 'cyan',
    Meta : {
      Title: '@:Route.Login.Title',
      Desc : '',
      Img  : ''
    }
  },

  TwoFALogin: {
    Title: '@:Users.TwoFA.Title',
    Icon : '',
    Color: '',
    Meta : {
      Title: '@:Route.TwoFALogin.Title',
      Desc : '',
      Img  : ''
    }
  },

  ResetPassword: {
    Title: '@:Users.ResetPassword.Title',
    Icon : '',
    Color: '',
    Meta : {
      Title: '@:Route.ResetPassword.Title',
      Desc : '',
      Img  : ''
    }
  },

  ChangePassword: {
    Title: '@:Users.ChangePassword.Title',
    Icon : '',
    Color: '',
    Meta : {
      Title: '@:Route.ChangePassword.Title',
      Desc : '',
      Img  : ''
    }
  },

  ForgotPassword: {
    Title: '@:Users.ForgotPassword.Title',
    Icon : '',
    Color: '',
    Meta : {
      Title: '@:Route.ForgotPassword.Title',
      Desc : '',
      Img  : ''
    }
  },

  Dashboard: {
    Title: '@:Dashboard.Title',
    Icon : 'mdi-view-dashboard',
    Color: 'primary',
    Meta : {
      Title: '@:Route.Dashboard.Title',
      Desc : '',
      Img  : ''
    }
  },

  System: {
    Title: '@:Settings.System.Title',
    Icon : 'mdi-tune-variant',
    Color: 'primary',
    Meta : {
      Title: '@:Route.System.Title',
      Desc : '',
      Img  : ''
    }
  },

  Settings: {
    Title: '@:Settings.Title',
    Icon : 'mdi-tune',
    Color: 'primary',
    Meta : {
      Title: '@:Route.Settings.Title',
      Desc : '',
      Img  : ''
    }
  },

  Companies: {
    Title: '@:Companies.Title',
    Icon : 'mdi-domain',
    Color: 'primary',
    Meta : {
      Title: '@:Route.Companies.Title',
      Desc : '',
      Img  : ''
    }
  },

  Users: {
    Title: '@:Users.Title',
    Icon : 'mdi-account-supervisor-circle',
    Color: 'primary',
    Meta : {
      Title: '@:Route.Users.Title',
      Desc : '',
      Img  : ''
    }
  },

  Roles: {
    Title: '@:Roles.Title',
    Icon : 'mdi-account-group',
    Color: 'primary',
    Meta : {
      Title: '@:Route.Roles.Title',
      Desc : '',
      Img  : ''
    }
  },

  Permissions: {
    Title: '@:Permissions.Title',
    Icon : 'mdi-account-lock',
    Color: 'purple',
    Meta : {
      Title: '@:Route.Permissions.Title',
      Desc : '',
      Img  : ''
    }
  }
}
