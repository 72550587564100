import { themeUtils } from '@/lib/utils'
import { i18n }       from '@/lang/lang'

// eslint-disable-next-line no-unused-vars
const PlaceHolder = () => themeUtils.importThemeComponent('components/common/PlaceHolder')
const Login = () => themeUtils.importThemeComponent('views/login/Login')
const TwoFALogin = () => themeUtils.importThemeComponent('views/login/TwoFALogin')
const ForgotPassword = () => themeUtils.importThemeComponent('views/login/ForgotPassword')
const ResetPassword = () => themeUtils.importThemeComponent('views/login/ResetPassword')
const ChangePassword = () => themeUtils.importThemeComponent('views/login/ChangePassword')
const Dashboard = () => themeUtils.importThemeComponent('views/dashboard/Dashboard')
const Settings = () => themeUtils.importThemeComponent('views/system/setting/Settings')
const Companies = () => themeUtils.importThemeComponent('views/system/company/Companies')
const Users = () => themeUtils.importThemeComponent('views/system/user/Users')
const Roles = () => themeUtils.importThemeComponent('views/system/role/Roles')
const Permissions = () => themeUtils.importThemeComponent('views/system/permission/Permissions')
const Elections = () => themeUtils.importThemeComponent('views/election/Elections')
const ElectionCategories = () => themeUtils.importThemeComponent('views/election/ElectionCategories')
const ElectionMembers = () => themeUtils.importThemeComponent('views/election/ElectionMembers')
const Members = () => themeUtils.importThemeComponent('views/member/Members')

export const AppRoutes = {
  SimpleRoutes: [
    {
      name    : 'Default',
      path    : '*',
      redirect: '/' + i18n.locale
    }
  ],

  LangRoutes: [
    {
      name     : 'Elections',
      path     : 'elections',
      component: Elections,
      meta     : {
        requiresAuth: true,
        layout      : 'default'
      }
    },
    {
      name     : 'ElectionMembers',
      path     : 'election/:id/members',
      component: ElectionMembers,
      meta     : {
        requiresAuth: true,
        layout      : 'default'
      }
    },
    {
      name     : 'ElectionCategories',
      path     : 'election-categories',
      component: ElectionCategories,
      meta     : {
        requiresAuth: true,
        layout      : 'default'
      }
    },
    {
      name     : 'Members',
      path     : 'members',
      component: Members,
      meta     : {
        requiresAuth: true,
        layout      : 'default'
      }
    },
    {
      name     : 'Login',
      path     : '',
      component: Login,
      meta     : {
        requiresAuth: false,
        layout      : 'login'
      }
    },
    {
      name     : 'TwoFALogin',
      path     : 'login/2fa',
      component: TwoFALogin,
      meta     : {
        requiresAuth: false,
        layout      : 'login'
      }
    },
    {
      name     : 'ForgotPassword',
      path     : 'forgot-password',
      component: ForgotPassword,
      meta     : {
        requiresAuth: false,
        layout      : 'login'
      }
    },
    {
      name     : 'ResetPassword',
      path     : 'reset-password',
      component: ResetPassword,
      meta     : {
        requiresAuth: false,
        layout      : 'login'
      }
    },
    {
      name     : 'ChangePassword',
      path     : 'change-password',
      component: ChangePassword,
      meta     : {
        requiresAuth: false,
        layout      : 'login'
      }
    },
    {
      name     : 'Dashboard',
      path     : 'dashboard',
      component: Dashboard,
      props    : true,
      meta     : {
        requiresAuth: true,
        layout      : 'default'
      }
    },
    {
      name     : 'System',
      path     : 'system',
      component: {
        render (c) {
          return c('router-view')
        }
      },
      redirect: { name: 'Settings' },
      meta    : { requiresAuth: true },
      children: [
        {
          name     : 'Companies',
          path     : 'companies',
          component: Companies,
          meta     : {
            requiresAuth: true,
            layout      : 'default'
          }
        },
        {
          name     : 'Users',
          path     : 'users',
          component: Users,
          meta     : {
            requiresAuth: true,
            layout      : 'default'
          }
        },
        {
          name     : 'Roles',
          path     : 'roles',
          component: Roles,
          meta     : {
            requiresAuth: true
          }
        },
        {
          name     : 'Permissions',
          path     : 'permissions',
          component: Permissions,
          meta     : {
            requiresAuth: true
          }
        },
        {
          name     : 'Settings',
          path     : 'settings',
          component: Settings,
          meta     : {
            requiresAuth: true,
            layout      : 'default'
          }
        }
      ]
    }
  ]
}

export default AppRoutes
