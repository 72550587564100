import BaseResource from '@/lib/data/resource/BaseResource'

export default class ElectionSettingResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.Participation = payload.Participation ?? null
    this.Candidates = payload.Candidates ?? []
    this.Categories = payload.Categories ?? []
  }

  /* PROPERTIES */

  /* METHODS */
}
