<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <data-card
        v-if="model"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :title="`${model.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isFormSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="isFormValid"
            >
              <v-container fluid>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="model.Name"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Name']"
                      :hint="$te(`${parentComponentName}.Form.Fields.Name.Hint`) ? $t(`${parentComponentName}.Form.Fields.Name.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.Name.Label`) ? $t(`${parentComponentName}.Form.Fields.Name.Label`) : 'Name'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Name')"
                      prepend-inner-icon="mdi-tag-text-outline"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-menu
                      ref="colorPickerMenu"
                      v-model="colorPickerVisible"
                      :close-on-click="true"
                      :close-on-content-click="false"
                      :return-value.sync="model.Color"
                      max-width="290"
                      min-width="290"
                      offset-y
                    >
                      <template #activator="{on, attrs}">
                        <v-text-field
                          v-model="model.Color"
                          :disabled="isReadOnly"
                          :error-messages="serverErrorMessages['Color']"
                          :hint="$te(`${parentComponentName}.Form.Fields.Color.Hint`) ? $t(`${parentComponentName}.Form.Fields.Color.Hint`) : ''"
                          :label="$te(`${parentComponentName}.Form.Fields.Color.Label`) ? $t(`${parentComponentName}.Form.Fields.Color.Label`) : 'Color'"
                          :readonly="isReadOnly"
                          :rules="model.validator.vuetifyFormFieldRules('Color')"
                          prepend-inner-icon="mdi-palette"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template #prepend>
                            <v-sheet
                              :color="model.Color"
                              height="25"
                              rounded
                              width="25"
                            />
                          </template>
                        </v-text-field>
                      </template>

                      <v-card>
                        <v-layout fluid>
                          <v-row no-gutters>
                            <v-col cols="12">
                              <v-color-picker
                                v-model="model.Color"
                                hide-mode-switch
                                mode="hexa"
                                @change="$refs.colorPickerMenu.save(model.Color)"
                              />
                            </v-col>

                            <v-col
                              class="text-right pa-2 px-4"
                              cols="12"
                            >
                              <v-btn
                                color="primary"
                                text
                                @click="colorPickerVisible = false"
                              >
                                {{ $t('Common.Button.Cancel') }}
                              </v-btn>

                              <v-btn
                                color="primary"
                                text
                                @click="$refs.colorPickerMenu.save(model.Color)"
                              >
                                {{ $t('Common.Button.Ok') }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-layout>
                      </v-card>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="model.Description"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Description']"
                      :hint="$te(`${parentComponentName}.Form.Fields.Description.Hint`) ? $t(`${parentComponentName}.Form.Fields.Description.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.Description.Label`) ? $t(`${parentComponentName}.Form.Fields.Description.Label`) : 'Description'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Description')"
                      prepend-inner-icon="mdi-subtitles-outline"
                    />
                  </v-col>
                </v-row>

                <v-row
                  v-if="userIsSuperAdmin"
                  dense
                >
                  <v-col cols="12">
                    <v-select
                      v-model="model.CompanyId"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['CompanyId']"
                      :hint="$t(`${parentComponentName}.Form.Fields.CompanyId.Hint`)"
                      :items="companies"
                      :label="$t(`${parentComponentName}.Form.Fields.CompanyId.Label`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('CompanyId')"
                      item-text="Name"
                      item-value="Id"
                      prepend-inner-icon="mdi-domain"
                    >
                      <template #item="{on, attrs, item}">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-content>
                            <v-list-item-title v-text="item.Name" />
                            <v-list-item-subtitle v-text="item.Description" />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <label class="grey--text caption">
                      {{ $t(`${ parentComponentName }.Form.Fields.Admin.Label`) }}
                    </label>
                    <v-switch
                      v-model="model.Admin"
                      :disabled="isReadOnly"
                      :label="model.Admin ? $t(`Common.Button.Toggle.ActiveM`) : $t(`Common.Button.Toggle.InactiveM`)"
                      :readonly="isReadOnly"
                      class="mt-1"
                      color="success"
                      inset
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="!!showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ isBoolean(showGenericError) ? $t('Common.Error.Generic') : showGenericError }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              :disabled="isFormSaving"
              large
              plain
              tile
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              v-if="!isReadOnly"
              :disabled="isFormSaving"
              :loading="isFormSaving"
              color="success"
              depressed
              @click="saveFromData"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { themeUtils } from '@/lib/utils'
import { isBoolean }  from '@/lib/utils/type'
import dialogVisible  from '@/mixins/dialog/dialogVisible'
import dialogData     from '@/mixins/dialog/dialogData'
import RoleModel      from '@/api/models/role/RoleModel'

const DataCard = () => themeUtils.importThemeComponent('components/common/DataCard')

export default {
  name      : 'RoleEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {
    companies: {
      type   : Array,
      default: () => []
    }
  },
  enums    : {},
  dataModel: RoleModel,
  data () {
    return {
      colorPickerVisible: false,
      colorPick         : ''
    }
  },
  computed: {},
  watch   : {
    isVisible (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.model.validator.validatorRules.CompanyId.required = this.userIsSuperAdmin
        })
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    ...{ isBoolean }
  }
}
</script>

<style scoped>

</style>
