<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <data-card
        v-if="model"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :title="`${model.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isFormSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="isFormValid"
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="model.CompanyId"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['CompanyId']"
                      :hint="$te(`${parentComponentName}.Form.Fields.CompanyId.Hint`) ? $t(`${parentComponentName}.Form.Fields.CompanyId.Hint`) : ''"
                      :items="companies"
                      :label="$te(`${parentComponentName}.Form.Fields.CompanyId.Label`) ? $t(`${parentComponentName}.Form.Fields.CompanyId.Label`) : 'CompanyId'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('CompanyId')"
                      item-text="Name"
                      item-value="Id"
                      @focusin="resetServerErrorMessage('CompanyId')"
                    >
                      <template #item="{on, attrs, item}">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-content>
                            <v-list-item-title v-text="item.Name" />
                            <v-list-item-subtitle v-text="item.Description" />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="model.Uid"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Uid']"
                      :hint="$te(`${parentComponentName}.Form.Fields.Uid.Hint`) ? $t(`${parentComponentName}.Form.Fields.Uid.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.Uid.Label`) ? $t(`${parentComponentName}.Form.Fields.Uid.Label`) : 'Uid'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Uid')"
                      @focusin="resetServerErrorMessage('Uid')"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="model.Corps"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Corps']"
                      :hint="$te(`${parentComponentName}.Form.Fields.Corps.Hint`) ? $t(`${parentComponentName}.Form.Fields.Corps.Hint`) : ''"
                      :items="$te(`${parentComponentName}.Form.Fields.Corps.Items`) ? $t(`${parentComponentName}.Form.Fields.Corps.Items`) : []"
                      :label="$te(`${parentComponentName}.Form.Fields.Corps.Label`) ? $t(`${parentComponentName}.Form.Fields.Corps.Label`) : 'Corps'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Corps')"
                      @focusin="resetServerErrorMessage('Corps')"
                    />
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      v-model="model.FirstName"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['FirstName']"
                      :hint="$te(`${parentComponentName}.Form.Fields.FirstName.Hint`) ? $t(`${parentComponentName}.Form.Fields.FirstName.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.FirstName.Label`) ? $t(`${parentComponentName}.Form.Fields.FirstName.Label`) : 'FirstName'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('FirstName')"
                      @focusin="resetServerErrorMessage('FirstName')"
                    />
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      v-model="model.LastName"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['LastName']"
                      :hint="$te(`${parentComponentName}.Form.Fields.LastName.Hint`) ? $t(`${parentComponentName}.Form.Fields.LastName.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.LastName.Label`) ? $t(`${parentComponentName}.Form.Fields.LastName.Label`) : 'LastName'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('LastName')"
                      @focusin="resetServerErrorMessage('LastName')"
                    />
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      v-model="model.FathersName"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['FathersName']"
                      :hint="$te(`${parentComponentName}.Form.Fields.FathersName.Hint`) ? $t(`${parentComponentName}.Form.Fields.FathersName.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.FathersName.Label`) ? $t(`${parentComponentName}.Form.Fields.FathersName.Label`) : 'FathersName'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('FathersName')"
                      @focusin="resetServerErrorMessage('FathersName')"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="model.Phone"
                      v-mask="'##########'"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Phone']"
                      :hint="$te(`${parentComponentName}.Form.Fields.Phone.Hint`) ? $t(`${parentComponentName}.Form.Fields.Phone.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.Phone.Label`) ? $t(`${parentComponentName}.Form.Fields.Phone.Label`) : 'Phone'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Phone')"
                      @focusin="resetServerErrorMessage('Phone')"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="model.Email"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Email']"
                      :hint="$te(`${parentComponentName}.Form.Fields.Email.Hint`) ? $t(`${parentComponentName}.Form.Fields.Email.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.Email.Label`) ? $t(`${parentComponentName}.Form.Fields.Email.Label`) : 'Email'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Email')"
                      type="email"
                      @focusin="resetServerErrorMessage('Email')"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <label class="grey--text caption">
                      {{ $t(`${parentComponentName}.Form.Fields.Active.Label`) }}
                    </label>
                    <v-switch
                      v-model="model.Active"
                      :disabled="isReadOnly"
                      :label="model.Active ? $t(`Common.Button.Toggle.Active`) : $t(`Common.Button.Toggle.Inactive`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Active')"
                      class="mt-1"
                      color="success"
                      inset
                      @focusin="resetServerErrorMessage('Active')"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="!!showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ isBoolean(showGenericError) ? $t('Common.Error.Generic') : showGenericError }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              :disabled="isFormSaving"
              large
              plain
              tile
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              v-if="!isReadOnly"
              :disabled="isFormSaving"
              :loading="isFormSaving"
              color="success"
              depressed
              @click="saveFromData"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { themeUtils } from '@/lib/utils'
import { isBoolean } from '@/lib/utils/type'
import dialogVisible from '@/mixins/dialog/dialogVisible'
import dialogData from '@/mixins/dialog/dialogData'
import MemberModel from '@/api/models/member/MemberModel'

const DataCard = () => themeUtils.importThemeComponent('components/common/DataCard')

export default {
  name      : 'MemberEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {
    companies: {
      type   : Array,
      default: () => []
    }
  },
  enums    : {},
  dataModel: MemberModel,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    ...{ isBoolean }
  }
}
</script>

<style scoped>

</style>
