import BaseResource from '@/lib/data/resource/BaseResource'

export default class ElectionCategoryResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.Name = payload.Name ?? ''
    this.Description = payload.Description ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
