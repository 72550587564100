import BaseValidator      from '@/lib/data/validator/BaseValidator'
import ValidatorTypesEnum from '@/lib/data/validator/ValidatorTypesEnum'

export default class TwoFAValidator extends BaseValidator {
  validatorRules = {
    Pin: {
      required: true,
      type    : ValidatorTypesEnum.Numeric,
      length  : 6
    },
    Token: {
      required: true,
      type    : ValidatorTypesEnum.String,
      length  : 64
    },
    Username: {
      required: true,
      type    : ValidatorTypesEnum.String
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
