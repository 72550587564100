import API             from '@/api/Api'
import ApiResponseEnum from '@/api/enums/ApiResponseEnum'
import UserModel       from '@/api/models/user/UserModel'
import { AES }         from '@/lib/crypto/crypto'

export default {
  name: 'AuthRestApi',
  data () {
    return {}
  },
  methods: {
    login (username, password) {
      this.$dataStore.User = null
      this.isAuthLoading = true

      API.Resource.User.Login(AES.encrypt(username), AES.encrypt(password))
        .then(response => {
          this.handleLoginResponse(response)
        })
        .catch(e => {
          this.handleLoginResponse(e.response)
        })
        .finally(() => {
          this.isAuthLoading = false
        })
    },

    handleLoginResponse (response) {
      const status = response.status
      const code = response.data?.code
      // eslint-disable-next-line no-unused-vars
      const message = response?.data?.message
      const success = response?.data?.success
      const data = response.data?.data || []
      // eslint-disable-next-line no-unused-vars
      const errors = response?.data?.errors || {}

      if (status === ApiResponseEnum.SUCCESS && success) {
        if (code === ApiResponseEnum.SUCCESS && data && data.token && data.user) {
          this.onLoginSuccess(response, data)
        } else if (code === ApiResponseEnum.FORCE_CHANGE_PASSWORD && data.token && data.email) {
          this.onForceChangePassword(response, data)
        } else if (code === ApiResponseEnum.FORCE_2FA && data.token) {
          this.onForce2FA(response, data)
        } else {
          this.onLoginError(response, data)
        }
      } else {
        this.onLoginError(response, data)
      }
    },

    onLoginSuccess (response, data) {
      this.authError = false
      this.authorizationToken = data.token
      this.user = new UserModel(data.user)

      this.$nextTick(() => {
        this.$bus.$emit('Auth:Login:Success', this.user)
        this.$router.replace({ name: 'Dashboard' })
          .catch(e => {})
          .finally(() => {
            if (response.data.reload) {
              location.reload()
            }
          })
      })
    },

    onLoginError (response, data) {
      this.$bus.$emit('Auth:Login:Error')
      this.authError = true
    },

    onForceChangePassword (response, data) {
      this.$router.push({
        name : 'ChangePassword',
        query: {
          token: data.token || '',
          email: data.email || ''
        }
      })
    },

    onForce2FA (response, data) {
      this.$router.push({
        name : 'TwoFALogin',
        query: {
          token   : data.token || '',
          username: data.username || '',
          expire  : data.expire || 60
        }
      })
    },

    logout () {
      this.isAuthLoading = true

      API.Resource.User.Logout()
        .then(response => {})
        .catch(e => {})
        .finally(() => {
          this.$dataStore.User = null

          this.isAuthLoading = false
          this.authError = false
          this.authorizationToken = ''
          this.user = null
          this.$router.push({
            name : 'Login',
            query: {}
          })
          this.$bus.$emit('Auth:Logout:Success')
        })
    }
  }
}
