import BaseModel                from '@/lib/data/model/BaseModel'
import ElectionSettingValidator from '@/api/validations/election/ElectionSettingValidator'
import ElectionSettingResource  from '@/api/resources/election/ElectionSettingResource'
import API                      from '@/api/Api'

export default class ElectionSettingModel extends BaseModel {
  constructor (payload = {}, resource = ElectionSettingResource, validator = ElectionSettingValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['Categories']
  }

  /* PROPERTIES */

  /* METHODS */

  async Get () {
    return await API.Resource.ElectionSetting.Get(this.Id)
  }

  async Create () {
    return await API.Resource.ElectionSetting.Create(this.clone())
  }

  async Update () {
    return await API.Resource.ElectionSetting.Update(this.Id, this.clone())
  }

  async UpdateOrCreate () {
    return await API.Resource.ElectionSetting.UpdateOrCreate(this.clone())
  }
}
