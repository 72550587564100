<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="fill-height">
          <v-card-title class="pa-2 d-block">
            <v-toolbar
              :color="!$vuetify.theme.dark ? 'grey lighten-3' : ''"
              extension-height="60"
              flat
            >
              <template #default>
                <v-toolbar-title>
                  {{ election.Name }}
                  <div class="caption grey--text">
                    {{ election.Description }}
                  </div>
                </v-toolbar-title>

                <div class="flex-grow-1" />

                <v-btn
                  v-if="userCanManage"
                  :disabled="!election.Sms || election.isElectionEnded()"
                  :small="$vuetify.breakpoint.mobile"
                  class="px-2 mr-1"
                  color="green"
                  dark
                  depressed
                  height="46"
                  @click="sendNotificationToAll('sms')"
                >
                  <v-icon :class="{'mr-1': !$vuetify.breakpoint.mobile}">
                    mdi-cellphone-message
                  </v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">
                    ΑΠΟΣΤΟΛΗ SMS
                  </template>
                </v-btn>

                <v-btn
                  v-if="userCanManage"
                  :disabled="!election.Email || election.isElectionEnded()"
                  :small="$vuetify.breakpoint.mobile"
                  class="px-2 mr-2"
                  color="indigo"
                  dark
                  depressed
                  height="46"
                  @click="sendNotificationToAll('email')"
                >
                  <v-icon :class="{'mr-1': !$vuetify.breakpoint.mobile}">
                    mdi-email
                  </v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">
                    ΑΠΟΣΤΟΛΗ EMAIL
                  </template>
                </v-btn>

                <data-table-search
                  v-if="dataTable.search.enabled"
                  v-model="dataTable.search.terms"
                />

                <data-table-filter
                  v-model="dataTable"
                  :filters="filterData"
                />
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            :headers="dataTable.headers"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.Active`]="{item}">
              <v-icon
                v-if="item.Active"
                color="green"
              >
                mdi-check
              </v-icon>
            </template>

            <template #[`item.SmsCount`]="{item}">
              <v-chip
                color="green"
                label
                small
              >
                {{ item.SmsCount }}
              </v-chip>
            </template>

            <template #[`item.EmailCount`]="{item}">
              <v-chip
                color="indigo"
                label
                small
              >
                {{ item.EmailCount }}
              </v-chip>
            </template>

            <template
              v-if="userCanManage || userCanAccess"
              #[`item.Actions`]="{item}"
            >
              <template v-if="userCanManage">
                <v-tooltip
                  :open-delay="650"
                  bottom
                  color="green"
                >
                  <template #activator="{on}">
                    <v-btn
                      :disabled="item.LoadingSms || !election.Sms || election.isElectionEnded()"
                      :loading="item.LoadingSms"
                      class="ml-1 white--text"
                      color="green"
                      depressed
                      fab
                      x-small
                      @click="sendNotificationToMember(item, 'sms')"
                      v-on="on"
                    >
                      <v-icon>mdi-cellphone-message</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('Common.Button.Send') }} {{ $t('Common.Button.Sms') }}
                  </span>
                </v-tooltip>
              </template>

              <template v-if="userCanManage">
                <v-tooltip
                  :open-delay="650"
                  bottom
                  color="indigo"
                >
                  <template #activator="{on}">
                    <v-btn
                      :disabled="item.LoadingEmail || !election.Email || election.isElectionEnded()"
                      :loading="item.LoadingEmail"
                      class="ml-1 white--text"
                      color="indigo"
                      depressed
                      fab
                      x-small
                      @click="sendNotificationToMember(item, 'email')"
                      v-on="on"
                    >
                      <v-icon>mdi-email</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('Common.Button.Send') }} {{ $t('Common.Button.Email') }}
                  </span>
                </v-tooltip>
              </template>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTable from '@/mixins/table/dataTable'
import DataTableActions from '@/mixins/table/dataTableActions'
import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import ElectionMemberModel from '@/api/models/election/ElectionMemberModel'
import { themeUtils } from '@/lib/utils'
import { capitalize } from '@/lib/utils/string'
import ElectionModel from '@/api/models/election/ElectionModel'
import { Base64AES } from '@/lib/crypto/crypto'
import ElectionMembersBeforeEnterMiddleware from '@/router/middleware/election/ElectionMembersBeforeEnterMiddleware'
import ElectionMembersBeforeLeaveMiddleware from '@/router/middleware/election/ElectionMembersBeforeLeaveMiddleware'

const DataTableFilter = () => themeUtils.importThemeComponent('components/common/dataTable/DataTableFilter')
const DataTableSearch = () => themeUtils.importThemeComponent('components/common/dataTable/DataTableSearch')

export default {
  name      : 'ElectionMembers',
  components: {
    DataTableSearch,
    DataTableFilter
  },
  directives      : {},
  mixins          : [i18nRouteMeta, DataTable, DataTableActions],
  beforeRouteEnter: ElectionMembersBeforeEnterMiddleware,
  beforeRouteLeave: ElectionMembersBeforeLeaveMiddleware,
  props           : {},
  dataStore       : {},
  enums           : {},
  dataModel       : ElectionMemberModel,
  data () {
    return {
      dataTable: {
        payload     : { Id: Base64AES.decrypt(this.$route.params.id) },
        customFilter: {},
        filter      : {
          Active: null
        },
        options: {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        headers: [
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.Id`) ? this.$t(`${this.$options.name}.Table.Headers.Id`) : '#',
            value   : 'Id',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.Uid`) ? this.$t(`${this.$options.name}.Table.Headers.Uid`) : 'Uid',
            value   : 'Uid',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.Corps`) ? this.$t(`${this.$options.name}.Table.Headers.Corps`) : 'Corps',
            value   : 'Corps',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.FirstName`) ? this.$t(`${this.$options.name}.Table.Headers.FirstName`) : 'FirstName',
            value   : 'FirstName',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.LastName`) ? this.$t(`${this.$options.name}.Table.Headers.LastName`) : 'LastName',
            value   : 'LastName',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.FathersName`) ? this.$t(`${this.$options.name}.Table.Headers.FathersName`) : 'FathersName',
            value   : 'FathersName',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.Phone`) ? this.$t(`${this.$options.name}.Table.Headers.Phone`) : 'Phone',
            value   : 'Phone',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.Email`) ? this.$t(`${this.$options.name}.Table.Headers.Email`) : 'Email',
            value   : 'Email',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.Active`) ? this.$t(`${this.$options.name}.Table.Headers.Active`) : 'Active',
            value   : 'Active',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.SmsCount`) ? this.$t(`${this.$options.name}.Table.Headers.SmsCount`) : 'SmsCount',
            value   : 'SmsCount',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.EmailCount`) ? this.$t(`${this.$options.name}.Table.Headers.EmailCount`) : 'EmailCount',
            value   : 'EmailCount',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${this.$options.name}.Table.Headers.Actions`) ? this.$t(`${this.$options.name}.Table.Headers.Actions`) : '',
            value   : 'Actions',
            align   : 'right',
            sortable: false,
            width   : 120
          }
        ]
      }
    }
  },
  computed: {
    election () {
      return new ElectionModel(this.$route.params.election)
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    sendNotificationToAll (type) {
      const typeName = type === 'email' ? capitalize(type) : type.toUpperCase()

      this.$root.$confirm(
        this.$t('Elections.Confirm.Notification.Title', { Type: typeName }),
        this.$t('Elections.Confirm.Notification.SubTitle', { Type: typeName }),
        this.$t('Elections.Confirm.Notification.Body', {
          Name   : this.election.Name,
          Type   : typeName,
          Company: this.election.Company
        }),
        {
          color        : type === 'email' ? 'indigo' : 'green',
          icon         : 'mdi-alert',
          validate     : false,
          coloredBorder: false,
          dark         : true,
          button       : {
            no : { title: this.$t('Common.Button.No') },
            yes: { title: this.$t('Common.Button.Yes') }
          }
        }
      ).then(response => {
        if (response) {
          this.election.NotifyAll(type)
        }
      })
    },

    sendNotificationToMember (member, type) {
      const typeName = type === 'email' ? capitalize(type) : type.toUpperCase()

      this.$root.$confirm(
        this.$t('Elections.Confirm.NotifyMember.Title', { Type: typeName }),
        this.$t('Elections.Confirm.NotifyMember.SubTitle', { Type: typeName }),
        this.$t('Elections.Confirm.NotifyMember.Body', {
          Name        : `${member.LastName} ${member.FirstName} `,
          ElectionName: this.election.Name,
          Company     : this.election.Company,
          Type        : typeName,
          Uid         : member.Uid
        }),
        {
          color        : type === 'email' ? 'indigo' : 'green',
          icon         : 'mdi-alert',
          validate     : false,
          coloredBorder: false,
          dark         : true,
          button       : {
            no : { title: this.$t('Common.Button.No') },
            yes: { title: this.$t('Common.Button.Yes') }
          }
        }
      ).then(response => {
        if (response) {
          if (type === 'email') {
            this.$set(member, 'LoadingEmail', true)
          } else {
            this.$set(member, 'LoadingSms', true)
          }

          this.election.NotifyMember(type, member.Id).finally(() => {
            if (type === 'email') {
              this.$set(member, 'LoadingEmail', false)
            } else {
              this.$set(member, 'LoadingSms', false)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
