import BaseModel        from '@/lib/data/model/BaseModel'
import SettingValidator from '@/api/validations/setting/SettingValidator'
import SettingResource  from '@/api/resources/setting/SettingResource'
import API              from '@/api/Api'

export default class SettingModel extends BaseModel {
  constructor (payload = {}, resource = SettingResource, validator = SettingValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */

  async Get () {
    return await API.Resource.Setting.Get(this.Id)
  }

  async Create () {
    return await API.Resource.Setting.Create(this.clone())
  }

  async Update () {
    return await API.Resource.Setting.Update(this.Id, this.clone())
  }

  async UpdateOrCreate () {
    return await API.Resource.Setting.UpdateOrCreate(this.clone())
  }

  async Delete () {
    return await API.Resource.Setting.Delete(this.Id)
  }
}
