<template>
  <v-row
    v-if="election && action"
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <data-card
        :color="action.Color"
        :colored-border="true"
        :sub-title="$t('Dashboard.Committee.Title')"
        :title="action.Title"
        icon="mdi-vote"
      >
        <template #toolbar-append>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form ref="form">
              <v-container fluid>
                <v-row>
                  <v-col
                    :class="`${action.Color}--text`"
                    class="title text-center"
                    cols="12"
                  >
                    Εισαγωγή Κωδικών
                  </v-col>

                  <template v-for="(member, index) in election.Committee">
                    <v-col
                      :key="`committee-member-${index}`"
                      cols="12"
                    >
                      <v-text-field
                        v-model="member.ConfirmPassword"
                        :append-icon="isMemberPasswordValid(member) ? 'mdi-check-circle' : ''"
                        :background-color="isMemberPasswordValid(member) ? 'success' : ''"
                        :clearable="!isMemberPasswordValid(member)"
                        :color="isMemberPasswordValid(member) ? 'white' : ''"
                        :hint="'Εισαγωγή μοναδικού κωδικού'"
                        :label="member.Name"
                        filled
                        persistent-hint
                        type="password"
                        @blur="isMemberPasswordValid(member)"
                        @input="isMemberPasswordValid(member)"
                      />
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              :disabled="isFormSaving"
              large
              plain
              tile
              @click="closeDialog"
            >
              {{ $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              :color="action.Color"
              :disabled="!isFormValid"
              :loading="isFormSaving"
              depressed
              @click="doAction"
              v-text="action.Title"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { themeUtils } from '@/lib/utils'
import { isBoolean } from '@/lib/utils/type'
import dialogVisible from '@/mixins/dialog/dialogVisible'
import API from '@/api/Api'
import { AES } from '@/lib/crypto/crypto'

const DataCard = () => themeUtils.importThemeComponent('components/common/DataCard')

export default {
  name      : 'CommitteeActionDialog',
  components: { DataCard },
  directives: {},
  mixins    : [dialogVisible],
  props     : {
    election: {
      type   : Object,
      default: undefined
    },
    action: {
      type   : Object,
      default: undefined
    }
  },
  enums: {},
  data () {
    return {
      isFormSaving: false
    }
  },
  computed: {
    isFormValid () {
      return this.election?.Committee?.every(member => this.isMemberPasswordValid(member)) || false
    }
  },
  watch: {
    isVisible (newVal) {
      this.resetForm()
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    ...{ isBoolean },

    isMemberPasswordValid (member) {

      return member.ConfirmPassword && AES.decrypt(member.Password) && member.ConfirmPassword === AES.decrypt(member.Password)
    },

    doAction () {
      this.isFormSaving = true

      API.Resource.Election.Action(this.election.Id, this.action.Action)
          .then(response => {
            this.handleDoActionResponse(response)
          })
          .catch(e => {
            this.handleDoActionResponse(e.response)
          })
          .finally(() => {
            this.isFormSaving = false
          })
    },

    handleDoActionResponse (response) {
      const data = API.responseData(response) || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.onDoActionSuccess(response, data)
      } else {
        this.onDoActionError(response, errors)
      }
    },

    onDoActionSuccess (response, data) {
      this.$emit('form:save:success', data)
      this.isVisible = false

      this.$bus.$emit('app-show-notification', {
        body   : this.$t('Common.Success.Generic'),
        type   : 'success',
        icon   : 'mdi-check-circle',
        timeout: 3000
      })
    },

    onDoActionError (response, errors) {
      this.$bus.$emit('app-show-notification', {
        body   : this.$t('Common.Error.Generic'),
        type   : 'error',
        icon   : 'mdi-alert',
        timeout: 3000
      })
    },

    resetForm () {
      if (this.$refs.form) this.$refs.form.reset()
    }
  }
}
</script>

<style scoped>

</style>
