import BaseModel             from '@/lib/data/model/BaseModel'
import SettingsCopyValidator from '@/api/validations/setting/SettingsCopyValidator'
import API                   from '@/api/Api'
import SettingsCopyResource  from '@/api/resources/setting/SettingsCopyResource'

export default class SettingsCopyModel extends BaseModel {
  constructor (payload = {}, resource = SettingsCopyResource, validator = SettingsCopyValidator) {
    super(payload, resource, validator)
  }

  /* PROPERTIES */

  /* METHODS */
  boot () {
    super.boot()

    this.guarded = []
  }

  async UpdateOrCreate () {
    return await API.Resource.Setting.Copy(this.clone())
  }
}
