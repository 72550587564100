export const App = {
  get Version () {
    return process.env.VUE_APP_VERSION
  },

  Theme: {
    get Default () {
      return process.env.VUE_APP_DEFAULT_THEME
    },
    Active: process.env.VUE_APP_ACTIVE_THEME
  },

  Config: {
    AppToolbarExtended: false
  }
}
