<template>
  <v-menu
    ref="timeMenu"
    v-model="timeMenu"
    :close-on-content-click="false"
    :disabled="disabled"
    :return-value.sync="selectedTime"
    max-width="290px"
    min-width="290px"
    offset-y
    transition="scale-transition"
  >
    <template #activator="{on, attrs}">
      <v-text-field
        v-model="selectedTime"
        :clearable="clearable"
        :dense="dense"
        :disabled="disabled"
        :filled="filled"
        :flat="flat"
        :height="height"
        :hide-details="hideDetails"
        :label="label"
        :outlined="outlined"
        :placeholder="placeholder"
        :rounded="rounded"
        :rules="rules"
        :shaped="shaped"
        :solo="solo"
        :solo-inverted="soloInverted"
        :style="`width: ${width}px;`"
        prepend-inner-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
        @click:clear="onTimeClear"
      />
    </template>

    <v-card flat>
      <v-time-picker
        v-if="timeMenu"
        v-model="selectedTime"
        :disabled="disabled"
        :locale="$i18n.locale"
        color="primary"
        first-day-of-week="1"
        format="24hr"
        full-width
        scrollable
      />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="onTimeDialogCancel"
        >
          {{ $t('Common.Button.Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="onTimeDialogOk"
        >
          {{ $t('Common.Button.Ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>

export default {
  name      : 'SelectTime',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    value: {
      type   : String,
      default: ''
    },

    placeholder: {
      type   : String,
      default: ''
    },

    label: {
      type   : String,
      default: ''
    },

    disabled: {
      type   : Boolean,
      default: false
    },

    clearable: {
      type   : Boolean,
      default: false
    },

    dense: {
      type   : Boolean,
      default: false
    },

    outlined: {
      type   : Boolean,
      default: false
    },

    solo: {
      type   : Boolean,
      default: false
    },

    soloInverted: {
      type   : Boolean,
      default: false
    },

    flat: {
      type   : Boolean,
      default: false
    },

    rounded: {
      type   : Boolean,
      default: false
    },

    shaped: {
      type   : Boolean,
      default: false
    },

    filled: {
      type   : Boolean,
      default: false
    },

    rules: {
      type   : Array,
      default: () => []
    },

    hideDetails: {
      type   : [Boolean, String],
      default: false
    },

    width: {
      type   : [String, Number],
      default: ''
    },

    height: {
      type   : [String, Number],
      default: ''
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {
      timeMenu    : false,
      selectedTime: null
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  watch: {
    model: {
      handler (newVal) {
        this.selectedTime = newVal
      },
      immediate: true
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onTimeDialogCancel () {
      this.timeMenu = false
    },

    onTimeDialogOk () {
      this.$refs.timeMenu.save(this.selectedTime)
      this.model = this.selectedTime
    },

    onTimeClear () {
      this.model = null
    }
  }
}
</script>

<style scoped>

</style>
