import BaseResource     from '@/lib/data/resource/BaseResource'
import { collect }      from '@/lib/utils/array'
import SettingItemModel from '@/api/models/setting/SettingItemModel'

export default class SettingResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.CompanyId = payload.CompanyId ?? null
    this.CompanyName = payload.CompanyName ?? ''
    this.Group = payload.Group ?? ''
    this.Item = payload.Item ?? ''
    this.Description = payload.Description ?? ''
    this.Value = collect(payload.Value ?? [], SettingItemModel)
    this.Global = payload.Global ?? false
    this.Admin = payload.Admin ?? false
    this.ApiGroup = payload.ApiGroup ?? []
    this.Active = payload.Active ?? true
  }

  /* PROPERTIES */
  get isSelectable () {
    return !this.Global
  }

  /* METHODS */
}
