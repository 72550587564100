import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class ElectionValidator extends BaseValidator {
  validatorRules = {
    Id: {
      required: false,
      type    : BaseValidator.Type.Number
    },
    CompanyId: {
      required: true,
      type    : BaseValidator.Type.Number
    },
    Name: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2,
      maxLen  : 255
    },
    Description: {
      required: false,
      type    : BaseValidator.Type.String,
      minLen  : 2,
      maxLen  : 255
    },
    StartedAt: {
      required: true
    },
    StartedTimeAt: {
      required: true,
      type    : BaseValidator.Type.TimeString
    },
    EndedAt: {
      required: true
    },
    EndedTimeAt: {
      required: true,
      type    : BaseValidator.Type.TimeString
    },
    Email: {
      required: true,
      type    : BaseValidator.Type.Boolean
    },
    Sms: {
      required: true,
      type    : BaseValidator.Type.Boolean
    },
    Committee: {
      required: true,
      type    : BaseValidator.Type.Array,
      minLen  : 0
    },
    'Committee.*.Name': {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2,
      maxLen  : 255
    },
    'Committee.*.Email': {
      required: true,
      type    : BaseValidator.Type.Email,
      maxLen  : 96
    },
    'Committee.*.Phone': {
      required  : false,
      type      : BaseValidator.Type.String,
      startsWith: '69',
      length    : 10
    },
    Categories: {
      required: true,
      type    : BaseValidator.Type.Array,
      minLen  : 0
    },
    Notes: {
      required: false,
      type    : BaseValidator.Type.String
    },
    NotesEmail: {
      required: false,
      type    : BaseValidator.Type.String
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
