import { API } from '@/api/Api'

export default {
  async Get (data = {}) {
    const Endpoint = API.Endpoint.Config.Get
    const Data = {
      fields      : defaultFields,
      skipResource: skipResource,
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  }

}

const skipResource = false

const defaultFields = []
