import BaseResource from '@/lib/data/resource/BaseResource'

export default class ForgotPasswordResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Email = payload.Email ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
