<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1024"
      persistent
      scrollable
    >
      <data-card
        v-if="model"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        title="Αποτελέσματα Εκλογών"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isFormSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="isFormValid"
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <h3>
                      <span class="font-weight-bold">ΣΥΜΜΕΤΟΧΗ:</span> {{ model.Participation }}
                    </h3>
                  </v-col>
                </v-row>

                <v-row>
                  <template v-for="(category) in model.Categories">
                    <v-row
                      :key="`category-${category.Id}`"
                      dense
                    >
                      <v-col cols="12">
                        <v-toolbar
                          :color="!$vuetify.theme.dark ? 'grey lighten-3' : ''"
                          dense
                          flat
                        >
                          <v-toolbar-title class="subtitle-2">
                            {{ category.Name }}
                          </v-toolbar-title>
                        </v-toolbar>
                      </v-col>

                      <v-col cols="12">
                        <v-simple-table dense>
                          <template #default>
                            <thead>
                              <tr>
                                <th
                                  class="text-left"
                                  style="min-width: 400px; width: 100%;"
                                >
                                  Name
                                </th>

                                <th
                                  class="text-left"
                                  style="min-width: 125px; width: 125px;"
                                >
                                  Percent ({{ getCategoryPercentTotal(category.Id) }}%)
                                </th>

                                <th
                                  class="text-left"
                                  style="min-width: 170px; width: 170px;"
                                >
                                  Votes ({{ getCategoryVotesTotal(category.Id) }})
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <template
                                v-for="(item, index) in model.Candidates.filter(candidate => category.Id === candidate.CategoryId)"
                              >
                                <tr :key="index">
                                  <td class="text-no-wrap">
                                    <v-text-field
                                      :items="category.Candidates"
                                      :label="$te(`${parentComponentName}.Form.Fields.CandidateId.Label`) ? $t(`${parentComponentName}.Form.Fields.CandidateId.Label`) : 'Candidate'"
                                      :value="`${item.Uid} - ${item.LastName} ${item.FirstName} ${item.FathersName ? `του ${item.FathersName}` : ''} ${item.Corps ? `(${item.Corps})` : '' }`"
                                      class="mt-4"
                                      dense
                                      filled
                                      flat
                                      readonly
                                      single-line
                                    />
                                  </td>

                                  <td class="text-no-wrap">
                                    <v-text-field
                                      :placeholder="'Percent'"
                                      :value="item.Pct"
                                      append-icon="mdi-percent"
                                      class="mt-4"
                                      dense
                                      filled
                                      flat
                                      readonly
                                      single-line
                                    />
                                  </td>

                                  <td class="text-no-wrap">
                                    <v-text-field
                                      v-model="item.Votes"
                                      v-mask="'##########'"
                                      :clearable="!isReadOnly"
                                      :disabled="isReadOnly"
                                      :placeholder="'Percent'"
                                      :readonly="isReadOnly"
                                      :rules="model.validator.vuetifyFormFieldRules(`Candidates.${index}.Votes`, `Candidates.*.Votes`)"
                                      append-icon="mdi-vote"
                                      class="mt-4"
                                      dense
                                      filled
                                      flat
                                      hide-spin-buttons
                                      min="0"
                                      single-line
                                      type="number"
                                    />
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </template>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="!!showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ isBoolean(showGenericError) ? $t('Common.Error.Generic') : showGenericError }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              :disabled="isFormSaving"
              large
              plain
              tile
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              v-if="!isReadOnly"
              :disabled="isFormSaving"
              :loading="isFormSaving"
              color="success"
              depressed
              @click="saveFromData"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { themeUtils }       from '@/lib/utils'
import { isBoolean }        from '@/lib/utils/type'
import dialogVisible        from '@/mixins/dialog/dialogVisible'
import dialogData           from '@/mixins/dialog/dialogData'
import ElectionResultsModel from '@/api/models/election/ElectionResultsModel'
import { sum }              from '@/lib/utils/array'

const DataCard = () => themeUtils.importThemeComponent('components/common/DataCard')

export default {
  name      : 'ElectionResultsDialog',
  components: {
    DataCard
  },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {},
  enums     : {},
  dataModel : ElectionResultsModel,
  data () {
    return {
      isFormSaving: false
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    ...{ isBoolean },

    getCategoryPercentTotal (categoryId) {
      const categoryCandidates = this.model?.Candidates?.filter(candidate => candidate.CategoryId === categoryId) || []

      return sum(categoryCandidates, 'Pct')
    },

    getCategoryVotesTotal (categoryId) {
      const categoryCandidates = this.model?.Candidates?.filter(candidate => candidate.CategoryId === categoryId) || []

      return sum(categoryCandidates, 'Votes')
    }
  }
}
</script>

<style scoped>

</style>
