<template>
  <v-card
    :color="iconBackgroundColor"
    :flat="flat"
    :to="to"
    class="fill-height"
    min-height="70"
    min-width="170px"
  >
    <v-list-item
      class="pa-0 fill-height"
    >
      <v-list-item-action
        class="text-center align-center justify-center align-content-center mx-1"
        style="min-width: 80px;"
      >
        <div class="text-center">
          <v-icon
            v-if="icon"
            :color="colorClass"
            :size="iconSize"
          >
            {{ icon }}
          </v-icon>

          <v-avatar
            v-if="img"
            :size="iconSize"
            class="ma-0 pa-0"
            color="white"
          >
            <img
              :height="iconSize"
              :src="img"
              width="auto"
            >
          </v-avatar>

          <div
            v-if="iconTitle"
            class="text-no-wrap font-weight-bold grey--text text--darken-3"
            style="font-size: 9px;"
          >
            {{ iconTitle }}
          </div>
        </div>
      </v-list-item-action>

      <v-list-item-content
        :class="[colorClass]"
        class="white--text text-center rounded-tr rounded-br pa-0 fill-height"
        style="min-height: 70px;"
      >
        <div>
          <v-list-item-title
            v-if="title"
            class="text-h6 text-no-wrap"
          >
            {{ title }}
          </v-list-item-title>

          <v-list-item-subtitle
            v-if="subTitle"
            class="text-no-wrap mt-1"
            style="font-size: 12px; color: rgba(255, 255, 255, 0.7);"
          >
            {{ subTitle }}
          </v-list-item-subtitle>
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name      : 'MiniStatsIconCard',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    flat: {
      type   : Boolean,
      default: false
    },
    title: {
      type   : String,
      default: ''
    },
    subTitle: {
      type   : String,
      default: ''
    },
    iconTitle: {
      type   : String,
      default: ''
    },
    colorClass: {
      type   : String,
      default: 'blue-grey'
    },
    icon: {
      type   : String,
      default: ''
    },
    iconBackgroundColor: {
      type   : String,
      default: 'grey lighten-4'
    },
    img: {
      type   : String,
      default: ''
    },
    iconSize: {
      type   : String,
      default: '32'
    },
    fixedIcon: {
      type   : Boolean,
      default: true
    },
    to: {
      type   : [String, Object],
      default: undefined
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
