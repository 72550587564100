import BaseModel                from '@/lib/data/model/BaseModel'
import ElectionResultsValidator from '@/api/validations/election/ElectionResultsValidator'
import ElectionResultsResource  from '@/api/resources/election/ElectionResultsResource'
import API                      from '@/api/Api'

export default class ElectionResultsModel extends BaseModel {
  constructor (payload = {}, resource = ElectionResultsResource, validator = ElectionResultsValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['Categories', 'Participation']
  }

  /* PROPERTIES */

  /* METHODS */

  async Get (electionId) {
    return await API.Resource.ElectionResults.Get(electionId)
  }

  async Create () {
    return await API.Resource.ElectionResults.Create(this.clone())
  }
}
