import BaseModel         from '@/lib/data/model/BaseModel'
import ElectionValidator from '@/api/validations/election/ElectionValidator'
import ElectionResource  from '@/api/resources/election/ElectionResource'
import API               from '@/api/Api'

export default class ElectionModel extends BaseModel {
  constructor (payload = {}, resource = ElectionResource, validator = ElectionValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['StartedTimeAt', 'StartedAtFmt', 'EndedTimeAt', 'EndedAtFmt', 'ActionStartedAt', 'ActionEndedAt', 'ActionVotesAt', 'CreatedAt']
  }

  /* PROPERTIES */

  /* METHODS */

  clone (includeGuardedFields = false, Resource = null) {
    const data = super.clone(includeGuardedFields, Resource)

    data.StartedAt = `${ this.StartedAt } ${ this.StartedTimeAt }`
    data.EndedAt = `${ this.EndedAt } ${ this.EndedTimeAt }`

    return data
  }

  async Get () {
    return await API.Resource.Election.Get(this.Id)
  }

  async Create () {
    return await API.Resource.Election.Create(this.clone())
  }

  async Update () {
    return await API.Resource.Election.Update(this.Id, this.clone())
  }

  async UpdateOrCreate () {
    return await API.Resource.Election.UpdateOrCreate(this.clone())
  }

  async Delete () {
    return await API.Resource.Election.Delete(this.Id)
  }

  async Action (action) {
    return await API.Resource.Election.Action(this.Id, action)
  }

  async NotifyAll (type = 'email') {
    return await API.Resource.Election.NotifyAll(this.Id, type)
  }

  async NotifyMember (type = 'email', memberId) {
    return await API.Resource.Election.NotifyMember(this.Id, memberId, type)
  }

  async SendVotersPdf (committeeMemberId) {
    return await API.Resource.Election.SendVotersPdf(this.Id, committeeMemberId)
  }

  async SearchVoter (searchString) {
    return await API.Resource.Election.SearchVoter(searchString, this.Id)
  }

  async GeneratePdf () {
    return await API.Resource.Election.GeneratePdf(this.Id)
  }

  async GetSettings () {
    return await API.Resource.ElectionSetting.Get(this.Id)
  }

  async GetResults () {
    return await API.Resource.ElectionResults.Get(this.Id)
  }

  async ClearCache () {
    return await API.Resource.Election.ClearCache(this.Id)
  }

  async SendResults () {
    return await API.Resource.Election.SendResults(this.Id)
  }

  isElectionStarted () {
    return !!this.ActionStartedAt
  }

  isElectionEnded () {
    return !!this.ActionEndedAt
  }

  isElectionFinal () {
    return !!this.ActionVotesAt
  }
}
