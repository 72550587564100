import * as stringFilters from './string/index'
import * as arrayFilters  from './array/index'
import * as otherFilters  from './other/index'

const VueFilters = {
  install: function (Vue, Options) {
    each(stringFilters, function (value, key) {
      Vue.filter(key, value)
    })

    each(otherFilters, function (value, key) {
      Vue.filter(key, value)
    })

    Vue.mixin({
      methods: {
        limitBy : arrayFilters.limitBy,
        filterBy: arrayFilters.filterBy,
        orderBy : arrayFilters.orderBy,
        find    : arrayFilters.find
      }
    })
  }
}

export default VueFilters

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(VueFilters)
}

const each = (obj, callback) => {
  let i, len
  if (Array.isArray(obj)) {
    for (i = 0, len = obj.length; i < len; i++) {
      if (callback(obj[i], i, obj) === false) {
        break
      }
    }
  } else {
    for (i in obj) {
      if (callback(obj[i], i, obj) === false) {
        break
      }
    }
  }
  return obj
}
