import BaseResource from '@/lib/data/resource/BaseResource'

export default class ElectionMemberResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.Uid = payload.Uid ?? ''
    this.Corps = payload.Corps ?? ''
    this.CompanyId = payload.CompanyId ?? null
    this.FirstName = payload.FirstName ?? ''
    this.LastName = payload.LastName ?? ''
    this.FathersName = payload.FathersName ?? ''
    this.Company = payload.Company ?? ''
    this.Phone = payload.Phone ?? ''
    this.Email = payload.Email ?? ''
    this.Active = payload.Active ?? false
    this.EmailCount = payload.EmailCount ?? 0
    this.SmsCount = payload.SmsCount ?? 0
    this.CreatedAt = payload.CreatedAt ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
