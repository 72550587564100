import { API } from '@/api/Api'

export default {
  async List (data = {}) {
    const Endpoint = API.Endpoint.User.List
    const Data = {
      page        : 1,
      limit       : 10,
      sort        : defaultSort,
      order       : defaultOrder,
      fields      : defaultFields,
      skipResource: skipResource,
      search      : '',
      filter      : {},
      ...data
    }
    // EventBus.$emit(EventHookEnum.User.Before.List, Data)
    return await API.RESTRequest(Endpoint, Data)
  },

  async Get (id, data = {}) {
    const Endpoint = API.Endpoint.User.Get
    Endpoint.Path = Endpoint.Path.replace('{id}', id)
    const Data = {
      ...(defaultFields.length && { fields: defaultFields.join(',') }),
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  },

  async Create (data = {}) {
    const Endpoint = API.Endpoint.User.Create
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  },

  async Update (id, data = {}) {
    const Endpoint = API.Endpoint.User.Update
    Endpoint.Path = Endpoint.Path.replace('{id}', id)
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  },

  async UpdateOrCreate (data = {}) {
    return await data.id || data.Id ? this.Update(data.id || data.Id, data) : this.Create(data)
  },

  async Delete (id, data = {}) {
    const Endpoint = API.Endpoint.User.Delete
    Endpoint.Path = Endpoint.Path.replace('{id}', id)
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  },

  async Login (username, password = {}) {
    return await API.RESTRequest(API.Endpoint.User.Login, {
      username: username,
      password: password
    })
  },

  async Logout () {
    return await API.RESTRequest(API.Endpoint.User.Logout)
  },

  async ForgotPassword (data = {}) {
    const Endpoint = API.Endpoint.User.ForgotPassword
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  },

  async ResetPassword (data = {}) {
    const Endpoint = API.Endpoint.User.ResetPassword
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  },

  async ChangePassword (data = {}) {
    const Endpoint = API.Endpoint.User.ChangePassword
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  },

  async TwoFALogin (data = {}) {
    const Endpoint = API.Endpoint.User.TwoFALogin
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  }
}

export const skipResource = false

export const defaultSort = [skipResource ? 'id' : 'Id']

export const defaultOrder = ['DESC']

export const defaultFields = []
