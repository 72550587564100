<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="600"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar color="primary">
            <v-icon dark>
              mdi-import
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Members.Import.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Members.Import.SubTitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-1">
          <v-form
            ref="dataForm"
            v-model="dataFormValid"
            @submit.prevent
          >
            <v-container
              fluid
            >
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="model.file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    filled
                    label="Excel File"
                    placeholder="Select an Excel File"
                    prepend-icon="mdi-microsoft-excel"
                    show-size
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            large
            plain
            tile
            @click="onCancelButtonClick"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>

          <v-btn
            :disabled="isLoading || !model.file || !electionId"
            :loading="isLoading"
            class="green white--text"
            depressed
            @click="onSaveButtonClick"
          >
            {{ $t('Common.Button.Import') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import API from '@/api/Api'

export default {
  name      : 'ElectionSettingsImportDialog',
  components: {},
  mixins    : [],
  props     : {
    election: {
      type   : Object,
      default: undefined
    },

    visible: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading    : false,
      dataFormValid: false,
      model        : {
        electionId: null,
        file      : null
      },
      validationRules: {
        dataForm: {
          file: []
        }
      }
    }
  },
  computed: {
    electionId () {
      return this.election?.Id || null
    },

    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },

  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.initData()

        this.$nextTick(() => {
          if (this.$refs.dataForm) this.$refs.dataForm.resetValidation()
        })
      }
    }
  },

  created () {
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy () {
  },

  methods: {
    initData () {
      this.model = {
        electionId: null,
        file      : null
      }
    },

    onSaveButtonClick () {
      this.$refs.dataForm.validate()

      if (this.dataFormValid) {
        this.isLoading = true

        this.model.electionId = this.electionId

        const formData = new FormData()
        formData.append('candidates', this.model.file)
        formData.append('electionId', this.model.electionId)

        API.Resource.Election.CandidatesSettingsImport(formData, { headers: { 'content-type': 'multipart/form-data' } })
          .then(response => {
            this.handleUploadResponse(response)
          })
          .catch(e => {
            this.handleUploadResponse(e.response)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    handleUploadResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.onUploadSuccess(response, data)
      } else {
        this.onUploadError(response, errors)
      }
    },
    onUploadSuccess (response, data) {
      this.$bus.$emit('app-show-notification', {
        body   : this.$t('Common.Success.Generic'),
        type   : 'success',
        icon   : 'mdi-check-circle',
        timeout: 3000
      })
      this.onCancelButtonClick()
    },
    onUploadError (response, errors) {
      this.$bus.$emit('app-show-notification', {
        body   : this.$t('Common.Error.Generic'),
        type   : 'error',
        icon   : 'mdi-alert',
        timeout: 3000
      })
    },

    onCancelButtonClick () {
      if (this.$refs.dataForm) this.$refs.dataForm.resetValidation()
      this.initData()
      this.isVisible = false
      this.isLoading = false
    }

  }
}
</script>
