import Vue           from 'vue'
import Vuetify       from 'vuetify/lib'
import el            from 'vuetify/es5/locale/el'
import en            from 'vuetify/es5/locale/en'
import overrideTheme from './overrideTheme'

Vuetify.config.silent = process.env.NODE_ENV === 'production' || false

Vue.use(Vuetify)

export default new Vuetify({
  rtl  : false,
  theme: {
    disable: false,
    dark   : true,
    default: 'light',
    options: {
      cspNonce        : undefined,
      minifyTheme     : undefined,
      themeCache      : undefined,
      customProperties: true,
      variations      : true
    },
    themes: {
      dark: {
        anchor   : '#1976d2',
        primary  : '#1976D2',
        secondary: '#424242',
        accent   : '#82B1FF',
        error    : '#FF5252',
        info     : '#2196F3',
        success  : '#4CAF50',
        warning  : '#FB8C00',
        ...overrideTheme.themes.dark
      },
      light: {
        anchor   : '#2196F3',
        primary  : '#2196F3',
        secondary: '#424242',
        accent   : '#FF4081',
        error    : '#FF5252',
        info     : '#2196F3',
        success  : '#4CAF50',
        warning  : '#FB8C00',
        ...overrideTheme.themes.light
      }
    }
  },
  lang: {
    current: process.env.VUE_APP_I18N_LOCALE,
    locales: {
      el,
      en
    }
  },
  icons: {
    iconfont: 'mdi',
    values  : {}
  },
  breakpoint: {
    mobileBreakpoint: 1264,
    scrollBarWidth  : 16,
    thresholds      : {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920
    }
  }
})
