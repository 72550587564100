<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500"
      persistent
      scrollable
    >
      <data-card
        v-if="model"
        :color="$t('Dashboard.Search.Color')"
        :colored-border="true"
        :icon="$t('Dashboard.Search.Icon')"
        :title="$t('Dashboard.Search.Dialog.Title')"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isFormSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="py-0"
          >
            <v-form
              ref="form"
              v-model="isFormValid"
            >
              <v-container
                class="pa-0"
                fluid
              >
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-list>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo">
                            mdi-account-details
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ model.FullName }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Ονοματεπώνυμο</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider inset />

                      <template v-if="model.Corps">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-call-merge
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>{{ model.Corps }}</v-list-item-title>
                            <v-list-item-subtitle>Κλάδος</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset />
                      </template>

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo">
                            mdi-card-account-details
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{ model.Uid }}</v-list-item-title>
                          <v-list-item-subtitle>Αρ. Μητρώου</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider inset />

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo">
                            mdi-phone
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{ model.Phone }}</v-list-item-title>
                          <v-list-item-subtitle>Τηλέφωνο</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider inset />

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo">
                            mdi-email
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{ model.Email }}</v-list-item-title>
                          <v-list-item-subtitle>Email</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>

                  <v-col
                    v-if="model.IsOffline"
                    cols="12"
                  >
                    <v-alert
                      dense
                      outlined
                      type="success"
                    >
                      Ο ψηφοφόρος είναι ήδη καταχωρημένος
                    </v-alert>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-divider />

          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              :disabled="isFormSaving"
              large
              plain
              tile
              @click="closeDialog"
            >
              {{ (isReadOnly || model.IsOffline) ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              v-if="userCan('ELECTIONS', 'ADD') && !model.IsOffline"
              :disabled="isFormSaving"
              :loading="isFormSaving"
              color="success"
              depressed
              @click="saveFromData"
              v-text="$t('Common.Button.Submit')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { themeUtils } from '@/lib/utils'
import { isBoolean } from '@/lib/utils/type'
import dialogVisible from '@/mixins/dialog/dialogVisible'
import dialogData from '@/mixins/dialog/dialogData'
import VoterModel from '@/api/models/member/VoterModel'

const DataCard = () => themeUtils.importThemeComponent('components/common/DataCard')

export default {
  name      : 'VoterViewDialog',
  components: { DataCard },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {},
  enums     : {},
  dataModel : VoterModel,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    ...{ isBoolean }
  }
}
</script>

<style scoped>

</style>
