import BaseResource from '@/lib/data/resource/BaseResource'
import { AES }      from '@/lib/crypto/crypto'

export default class CommitteeResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.Title = payload.Title ?? ''
    this.Name = payload.Name ?? ''
    this.Email = payload.Email ?? ''
    this.Phone = payload.Phone ?? ''
    this.Password = payload.Password ?? this.generateRandomPassword(true)
  }

  /* PROPERTIES */

  /* METHODS */

  generateRandomPassword (encrypted) {
    if (encrypted) return AES.encrypt(Math.floor(Math.random() * Date.now()).toString(16).toUpperCase())

    return Math.floor(Math.random() * Date.now()).toString(16).toUpperCase()
  }
}
