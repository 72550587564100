import BaseModel        from '@/lib/data/model/BaseModel'
import { pascalCase }   from 'change-case'
import { toPascalCase } from '@/lib/utils/string'
import { clone }        from '@/lib/utils/helper'
import API              from '@/api/Api'
import ApiResponseEnum  from '@/api/enums/ApiResponseEnum'

export default {
  name     : 'FormDataCommon',
  dataModel: BaseModel,
  data () {
    return {
      isFormSaving       : false,
      isFormValid        : false,
      formData           : null,
      showGenericError   : '',
      serverErrorMessages: {}
    }
  },

  props: {
    item: {
      type   : [BaseModel, Object],
      default: undefined
    },
    itemViewMode: {
      type   : Boolean,
      default: false
    }
  },

  created () {
    this.initModel()
  },

  methods: {
    initModel (resetForm = false) {
      this.model = null
      this.$nextTick(() => {
        if (resetForm) this.resetForm()
        // eslint-disable-next-line new-cap
        this.model = this.item && this.item instanceof BaseModel ? new this.item.constructor(this.item || {}) : this.$options.dataModel ? new this.$options.dataModel(this.item || {}) : new BaseModel(this.item || {})
        if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      })
    },

    resetForm () {
      if (this.$refs.form) this.$refs.form.reset()
    },

    resetFormValidation () {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },

    validateForm (deep = true) {
      let retVal = false
      if (this.$refs?.form) retVal = this.$refs.form.validate()
      if (this.model?.validator) retVal = (deep ? this.model.validate() : this.model.validator.validate())
      this.isFormValid = retVal

      // eslint-disable-next-line no-console
      Object.entries(this.model.errors).length && console.warn('VALIDATOR:: ', this.model.validator.constructor.name, this.model.errors, this.model)

      return retVal
    },

    saveFromData () {
      if (this.model && (typeof this.model?.UpdateOrCreate === 'function' || typeof this.model.Create === 'function')) {
        if (this.isFormSaving || !this.validateForm()) return
        if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
        this.isFormSaving = true

        this.model[typeof this.model?.UpdateOrCreate === 'function' ? 'UpdateOrCreate' : 'Create']()
          .then(response => {
            this.handleFormSaveResponse(response)
          })
          .catch(error => {
            this.handleFormSaveResponse(error.response)
          })
          .finally(() => {
            this.isFormSaving = false
          })
      } else {
        // eslint-disable-next-line no-console
        console.warn('CREATE YOUR OWN "saveFromData" FUNCTION IN YOUR COMPONENT OR IMPLEMENT/ADD "UpdateOrCreate" or "Create" FUNCTION IN YOUR MODEL')
      }
    },

    handleFormSaveResponse (response) {
      const data = API.responseData(response) || {}
      const errors = API.responseErrors(response) || {}
      const status = response.status
      const message = response?.data?.message

      if (API.isResponseSuccess(response)) {
        this.formSaveSuccess(data)
      } else {
        if (status === ApiResponseEnum.VALIDATOR_ERROR) {
          this.formSaveError(message, true)
        } else if (status === ApiResponseEnum.GENERIC_ERROR) {
          this.setServerErrorMessages(errors)
        } else {
          this.formSaveError(message, true)
        }
      }
    },

    formSaveSuccess (data) {
      this.showGenericError = ''
      this.$emit('form:save:success', data)
      if (typeof this.closeDialog === 'function') this.closeDialog()

      /* this.$bus.$emit('app-show-notification', {
        body   : this.$t('Common.Success.Generic'),
        type   : 'success',
        icon   : 'mdi-check-circle',
        timeout: 3000
      }) */
    },

    formSaveError (error, isGenericError = false) {
      this.showGenericError = isGenericError && error || isGenericError || ''
      this.$emit('form:save:error', error)

      /* this.$bus.$emit('app-show-notification', {
        body   : this.$t('Common.Error.Generic'),
        type   : 'error',
        icon   : 'mdi-alert',
        timeout: 3000
      }) */
    },

    setServerErrorMessages (messages) {
      for (const key in messages) {
        if (messages.hasOwnProperty(key)) {
          this.serverErrorMessages[key] = messages[key]
          this.serverErrorMessages[pascalCase(key)] = messages[key]
        }
      }
      this.formSaveError(messages)
    },

    clearServerErrorMessages () {
      this.showGenericError = ''
      const tmpMessages = {}
      for (const key in this.serverErrorMessages) if (this.serverErrorMessages.hasOwnProperty(key)) tmpMessages[key] = []
      this.serverErrorMessages = clone(tmpMessages)
    },

    resetServerErrorMessage (fieldName) {
      this.serverErrorMessages && fieldName && delete this.serverErrorMessages[toPascalCase(fieldName)]
    }
  },

  computed: {
    isModelValid () {
      const retVal = this.model.validate()
      // eslint-disable-next-line no-console
      Object.entries(this.model.errors).length && console.warn('VALIDATOR:: ', this.model.validator.constructor.name, this.model.errors, this.model)

      return retVal
    },

    model: {
      get () {
        return this.formData
      },
      set (val) {
        this.formData = val
      }
    }
  }
}
