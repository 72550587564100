import BaseResource from '@/lib/data/resource/BaseResource'

export default class VoterResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.Uid = payload.Uid ?? null
    this.VoterId = payload.VoterId ?? null
    this.ElectionId = payload.ElectionId ?? null
    this.CompanyId = payload.CompanyId ?? null
    this.FirstName = payload.FirstName ?? ''
    this.LastName = payload.LastName ?? ''
    this.FullName = `${payload.LastName} ${payload.FirstName} ${payload.FathersName ? `του ${payload.FathersName}` : ''}`
    this.FathersName = payload.FathersName ?? ''
    this.Corps = payload.Corps ?? ''
    this.Phone = payload.Phone ?? ''
    this.Email = payload.Email ?? ''
    this.Active = payload.Active ?? false
    this.IsOffline = payload.IsOffline ?? false
  }

  /* PROPERTIES */

  /* METHODS */
}
