import BaseResource from '@/lib/data/resource/BaseResource'

export default class SettingsCopyResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.definePrivateProperty('SettingIds', [])

    this.CompanyIds = payload.CompanyIds ?? []
    this.SettingIds = payload.SettingIds ?? []
    this.ForceUpdate = payload.ForceUpdate ?? false
  }

  /* PROPERTIES */

  get SettingIds () {
    return this._SettingIds
  }

  set SettingIds (val) {
    this._SettingIds = val.map(item => item.Id)
  }

  /* METHODS */
}
