import BaseModel         from '@/lib/data/model/BaseModel'
import DashboardResource from '@/api/resources/dashboard/DashboardResource'
import API               from '@/api/Api'

export default class DashboardModel extends BaseModel {
  constructor (payload = {}, resource = DashboardResource, validator = null) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
  async Get () {
    return await API.Resource.Dashboard.Get()
  }
}
