export default {

  Title: 'Ψηφοφόροι',

  Table: {
    Button: {
      New: 'Νέο Μέλος'
    },

    Headers: {
      Id         : '#',
      Uid        : 'ΑΡ. ΤΑΥΤΟΤΗΤΑΣ',
      Corps      : 'ΚΛΑΔΟΣ',
      FirstName  : 'ΟΝΟΜΑ',
      LastName   : 'ΕΠΩΝΥΜΟ',
      FathersName: 'ΠΑΤΡΩΝΥΜΟ',
      Email      : 'EMAIL',
      Phone      : 'ΤΗΛΕΦΩΝΟ',
      Active     : 'ΕΝΕΡΓΟΣ',
      SmsCount   : 'SMS',
      EmailCount : 'EMAIL',
      Actions    : ''
    },

    Filter: {
      Active: {
        Type       : 'v-btn-toggle',
        Label      : 'Ψηφοφόροι',
        PlaceHolder: 'Όλοι οι Ψηφοφόροι',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 'null',
            Name : 'Όλα'
          },
          {
            Value: 1,
            Name : 'Ενεργά'
          },
          {
            Value: 0,
            Name : 'Ανενεργά'
          }
        ]
      }
    }
  }
}
