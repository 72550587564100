export default {

  Title: 'Πίνακας Ελέγχου',

  Election: {
    Title: 'Εκλογές',
    Icon : 'mdi-vote',
    Color: 'green'
  },

  Search: {
    Title: 'Αναζήτηση Ψηφοφόρου',
    Icon : 'mdi-card-account-details',
    Color: 'lime',
    Form : {
      Field: {
        Label: 'Αριθμός Ταυτότητας Ψηφοφόρου',
        Icon : 'mdi-card-account-details'
      },
      Button: {
        Label: 'Αναζήτηση',
        Icon : 'mdi-account-search'
      },
      Error: {
        NotFound: 'Δεν βρέθηκε ψηφοφόρος με αυτό τον αριθμό ταυτότητας ή ο ψηφοφόρος έχει ψηφίσει ηλεκτρονικά.'
      }
    },
    Dialog: {
      Title: 'Στοιχεία Ψηφοφόρου'
    }
  },

  Committee: {
    Title: 'Εφορευτική Επιτροπή',
    Icon : 'mdi-badge-account-horizontal',
    Color: 'teal'
  },

  Candidates: {
    Title   : 'Ψηφοδέλτιο',
    SubTitle: 'Υποψήφιοι',
    Icon    : 'mdi-ballot',
    Color   : 'primary'
  },

  Members: {
    Title: 'Ψηφοφόροι',
    Icon : 'mdi-account-group',
    Color: 'primary'
  }

}
