import axios            from 'axios'
import { print }        from 'graphql/language/printer'
import { AES, HmacMD5 } from '@/lib/crypto/crypto'
import { i18n }         from '@/lang/lang'
import ApiResponseEnum  from '@/api/enums/ApiResponseEnum'

const authorizationBearerTokenKey = HmacMD5.hash('authBearerToken')
const errorCodes = [ApiResponseEnum.FORBIDDEN, ApiResponseEnum.UNAUTHORIZED]

export const GQL = {
  /**
   * Axios Client
   */
  Client: axios.create({
    baseURL        : process.env.VUE_APP_GRAPHQL_HTTP || '',
    timeout        : parseInt(process.env.VUE_APP_GRAPHQL_TIMEOUT) || 30000,
    responseType   : 'json',
    crossDomain    : true,
    withCredentials: true,
    headers        : {
      'Content-Type'    : 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }),

  /**
   * Request
   *
   * @param Query
   * @param Variables
   * @param Config
   * @returns {Promise<AxiosResponse<any>>}
   * @constructor
   */
  async Request (Query, Variables = {}, Config = {}) {
    const GQLQuery = {
      operationName: getOperationName(Query),
      query        : print(Query),
      variables    : Variables
    }

    return await GQL.Client.post('', GQLQuery, { params: {}, ...Config })
  },

  /**
   * RequestAll
   *
   * @param GQLRequests {Array<Request>}
   * @returns {Promise<any[]>}
   * @constructor
   */
  async RequestAll (GQLRequests) {
    return await Promise.all(GQLRequests)
  }
}

export const getOperationName = (documentNode) => documentNode?.definitions?.filter(definition => definition.kind === 'OperationDefinition' && definition.name).map((operationDefinitionNode) => operationDefinitionNode?.name?.value)[0] || null

GQL.Client.interceptors.request.use((requestConfig) => {
  const bearerToken = window.Vue && AES.decrypt(window.Vue.$sessionStorage.get(authorizationBearerTokenKey, null)) || null
  const bearerAuthHeader = bearerToken ? { Authorization: `Bearer ${ bearerToken }` } : {}

  const defaultRequestParams = {}
  const defaultRequestHeaders = {
    'Accept-Language': i18n.code,
    ...bearerAuthHeader
  }

  requestConfig.headers = Object.assign({}, defaultRequestHeaders || {}, requestConfig.headers || {})
  requestConfig.params = Object.assign({}, defaultRequestParams || {}, requestConfig.params || {})
  return requestConfig
}, function (error) {
  return Promise.reject(error)
})

GQL.Client.interceptors.response.use((response) => {
  return response
}, function (error) {
  if (error && (error.message === 'Network Error' || errorCodes.includes(error?.response?.status))) {
    window.Vue.user = null
    if (window.Vue.$router.currentRoute.name !== 'Login') {
      window.Vue.$router.push({
        name : 'Login',
        query: {}
      })
    }
  }
  return Promise.reject(error)
})

export default GQL
