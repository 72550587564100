<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="fill-height">
          <v-card-title class="pa-2 d-block">
            <v-toolbar
              :color="!$vuetify.theme.dark ? 'grey lighten-3' : ''"
              extension-height="60"
              flat
            >
              <template #default>
                <v-btn
                  v-if="userCanAdd"
                  :fab="$vuetify.breakpoint.mobile"
                  :small="$vuetify.breakpoint.mobile"
                  class="px-2 mr-1"
                  color="primary"
                  @click="createItem()"
                >
                  <v-icon :class="{'mr-1': !$vuetify.breakpoint.mobile}">
                    mdi-plus
                  </v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">
                    {{ $t(`${ $options.name }.Table.Button.New`).toLocaleUpperCase($i18n.locale) }}
                  </template>
                </v-btn>

                <v-btn
                  v-if="userCan('Users', PermissionEnum.ACCESS)"
                  :fab="$vuetify.breakpoint.mobile"
                  :small="$vuetify.breakpoint.mobile"
                  :to="{name: 'Users'}"
                  class="px-2 mr-1"
                  color="blue-grey"
                  dark
                >
                  <v-icon
                    :class="{'mr-1': !$vuetify.breakpoint.mobile}"
                    v-text="$t('Route.Users.Icon')"
                  />
                  <template v-if="!$vuetify.breakpoint.mobile">
                    {{ $t('Route.Users.Title').toLocaleUpperCase($i18n.locale) }}
                  </template>
                </v-btn>

                <v-btn
                  v-if="userCan('Permissions', PermissionEnum.ACCESS)"
                  :fab="$vuetify.breakpoint.mobile"
                  :small="$vuetify.breakpoint.mobile"
                  :to="{name: 'Permissions'}"
                  class="px-2 mr-1"
                  color="blue-grey"
                  dark
                >
                  <v-icon
                    :class="{'mr-1': !$vuetify.breakpoint.mobile}"
                    v-text="$t('Route.Permissions.Icon')"
                  />
                  <template v-if="!$vuetify.breakpoint.mobile">
                    {{ $t('Route.Permissions.Title').toLocaleUpperCase($i18n.locale) }}
                  </template>
                </v-btn>

                <div class="flex-grow-1" />

                <data-table-search
                  v-if="dataTable.search.enabled"
                  v-model="dataTable.search.terms"
                />

                <data-table-filter
                  v-model="dataTable"
                  :filters="filterData"
                />
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            :headers="dataTable.headers"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #[`item.Name`]="{item}">
              <div class="d-inline-flex">
                <v-chip
                  :color="item.Color"
                  class="px-1 mr-2"
                  label
                  style="width: 5px"
                />
              </div>

              <div class="d-inline-block">
                <div>
                  {{ item.Name }}
                </div>
                <div class="caption grey--text">
                  {{ item.Description }}
                </div>
              </div>
            </template>

            <template #[`item.Admin`]="{item}">
              <v-icon
                :color="item.Admin ? 'green' : 'grey'"
                v-text="item.Admin ? 'mdi-check-circle' : 'mdi-close-circle'"
              />
            </template>

            <template
              v-if="userCanDelete || userCanManage || userCanAccess"
              #[`item.Actions`]="{item}"
            >
              <v-speed-dial
                v-model="item.fab"
                direction="left"
                right
              >
                <template #activator>
                  <v-btn
                    v-model="item.fab"
                    color="blue darken-2"
                    dark
                    depressed
                    fab
                    x-small
                  >
                    <v-icon v-if="item.fab">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-menu
                    </v-icon>
                  </v-btn>
                </template>

                <v-tooltip
                  v-if="userCanDelete"
                  :open-delay="650"
                  bottom
                  color="red"
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userCanDelete"
                      :disabled="item.loadingDelete"
                      :loading="item.loadingDelete"
                      class="ml-1 white--text"
                      color="red"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="deleteItem(item)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Common.Button.Delete')" />
                </v-tooltip>

                <v-tooltip
                  v-if="userCanAccess"
                  :open-delay="650"
                  bottom
                  color="info"
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userCanAccess"
                      :disabled="item.loadingView"
                      :loading="item.loadingView"
                      class="ml-1 white--text"
                      color="info"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="viewItem(item)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Common.Button.View')" />
                </v-tooltip>

                <v-tooltip
                  v-if="userCanManage"
                  :open-delay="650"
                  bottom
                  color="grey"
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userCanManage"
                      :disabled="item.loadingEdit"
                      :loading="item.loadingEdit"
                      class="ml-1 white--text"
                      color="grey"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="editItem(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Common.Button.Edit')" />
                </v-tooltip>

                <v-tooltip
                  v-if="userCanManage"
                  :color="$t('Route.Permissions.Color')"
                  :open-delay="650"
                  bottom
                >
                  <template #activator="{on}">
                    <v-btn
                      v-if="userCanManage"
                      :color="$t('Route.Permissions.Color')"
                      :disabled="item.loadingEditPerms"
                      :loading="item.loadingEditPerms"
                      class="ml-1 white--text"
                      depressed
                      fab
                      x-small
                      v-on="on"
                      @click.native="item.fab = false"
                      @click.stop="editItemPermissions(item)"
                    >
                      <v-icon>{{ $t('Route.Permissions.Icon') }}</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('Roles.Permissions.Title')" />
                </v-tooltip>
              </v-speed-dial>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <role-edit-dialog
      v-if="userCanAccess || userCanAdd || userCanManage"
      :companies="filterData.CompanyId.Items"
      :item="selectedItem"
      :item-view-mode="itemViewMode"
      :visible.sync="editDialogVisible"
      @form:save:success="getData"
    />

    <role-permissions-edit-dialog
      v-if="userCanAccess || userCanAdd || userCanManage"
      :item="selectedItem"
      :visible.sync="editPermissionsDialogVisible"
      @form:save:success="getData"
    />
  </v-container>
</template>

<script>
import DataTable        from '@/mixins/table/dataTable'
import DataTableActions from '@/mixins/table/dataTableActions'
import i18nRouteMeta    from '@/mixins/i18n/i18nRouteMeta'
import RoleModel        from '@/api/models/role/RoleModel'
import { themeUtils }   from '@/lib/utils'
import PermissionEnum   from '@/api/enums/PermissionEnum'
import API              from '@/api/Api'
import { collect }      from '@/lib/utils/array'
import CompanyModel     from '@/api/models/company/CompanyModel'

const DataTableFilter = () => themeUtils.importThemeComponent('components/common/dataTable/DataTableFilter')
const DataTableSearch = () => themeUtils.importThemeComponent('components/common/dataTable/DataTableSearch')
const RoleEditDialog = () => themeUtils.importThemeComponent('views/system/role/RoleEditDialog')
const RolePermissionsEditDialog = () => themeUtils.importThemeComponent('views/system/role/RolePermissionsEditDialog')

export default {
  name      : 'Roles',
  components: {
    RolePermissionsEditDialog,
    DataTableSearch,
    DataTableFilter,
    RoleEditDialog
  },
  directives: {},
  mixins    : [i18nRouteMeta, DataTable, DataTableActions],
  props     : {},
  dataStore : {},
  enums     : {},
  dataModel : RoleModel,
  data () {
    return {
      editPermissionsDialogVisible: false,
      dataTable                   : {
        search: {
          enabled: false
        },
        customFilter: {},
        filter      : {
          Admin    : null,
          CompanyId: null
        },
        options: {
          sortBy  : ['Name'],
          sortDesc: [false]
        },
        headers: [
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Id`) ? this.$t(`${ this.$options.name }.Table.Headers.Id`) : '#',
            value   : 'Id',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Name`) ? this.$t(`${ this.$options.name }.Table.Headers.Name`) : 'Name',
            value   : 'Name',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.UsersCount`) ? this.$t(`${ this.$options.name }.Table.Headers.UsersCount`) : 'UsersCount',
            value   : 'UsersCount',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Admin`) ? this.$t(`${ this.$options.name }.Table.Headers.Admin`) : 'Admin',
            value   : 'Admin',
            align   : 'center',
            class   : 'pl-8',
            sortable: true
          },
          {
            text    : this.$te(`${ this.$options.name }.Table.Headers.Actions`) ? this.$t(`${ this.$options.name }.Table.Headers.Actions`) : '',
            value   : 'Actions',
            align   : 'right',
            sortable: false,
            width   : 40
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    editItemPermissions (item) {
      this.$set(item, 'loadingEditPerms', true)
      this.getItem(item, 'editPermissionsDialogVisible').finally(() => {
        this.$set(item, 'loadingEditPerms', false)
      })
    },

    getFilterData () {
      this.getCompanies()
    },

    getCompanies () {
      if (!this.userCan('Company', PermissionEnum.ACCESS)) return

      API.Resource.Company.DropDownList()
        .then(response => {
          if (API.isResponseSuccess(response)) {
            this.filterData.CompanyId.Items = collect(response.data?.data?.items || [], CompanyModel)
          }
        })
        .catch(e => { })
        .finally(() => { })
    }
  }
}
</script>

<style scoped>

</style>
