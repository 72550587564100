import BaseModel        from '@/lib/data/model/BaseModel'
import RoleValidator    from '@/api/validations/role/RoleValidator'
import RoleResource     from '@/api/resources/role/RoleResource'
import RoleSaveResource from '@/api/resources/role/RoleSaveResource'
import API              from '@/api/Api'

export default class RoleModel extends BaseModel {
  constructor (payload = {}, resource = RoleResource, validator = RoleValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */

  async Get () {
    return await API.Resource.Role.Get(this.Id)
  }

  async Create () {
    return await API.Resource.Role.Create(this.clone(false, RoleSaveResource))
  }

  async Update () {
    return await API.Resource.Role.Update(this.Id, this.clone(false, RoleSaveResource))
  }

  async UpdateOrCreate () {
    return await API.Resource.Role.UpdateOrCreate(this.clone(false, RoleSaveResource))
  }

  async Delete () {
    return await API.Resource.Role.Delete(this.Id)
  }
}
