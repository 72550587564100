import { API } from '@/api/Api'

export default {

  async Get (id, data = {}) {
    const Endpoint = API.Endpoint.ElectionResults.Get
    Endpoint.Path = Endpoint.Path.replace('{id}', id)
    const Data = {
      ...(defaultFields.length && { fields: defaultFields.join(',') }),
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  },

  async Create (data = {}) {
    const Endpoint = API.Endpoint.ElectionResults.Create
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  }

}

const defaultFields = []
