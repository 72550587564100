import BaseResource from '@/lib/data/resource/BaseResource'

export default class CompanyResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.Name = payload.Name ?? ''
    this.Description = payload.Description ?? ''
    this.CompanyId = payload.CompanyId ?? null
    this.CompanyName = payload.CompanyName ?? ''
    this.EmailIdentity = payload.EmailIdentity ?? ''
    this.SmsIdentity = payload.SmsIdentity ?? ''
    this.Active = payload.Active ?? false
    this.CreatedAt = payload.CreatedAt ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
