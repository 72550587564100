import { API } from '@/api/Api'

export default {
  async List (data = {}) {
    const Endpoint = API.Endpoint.Election.List
    const Data = {
      page        : 1,
      limit       : 10,
      sort        : defaultSort,
      order       : defaultOrder,
      fields      : defaultFields,
      skipResource: skipResource,
      search      : '',
      filter      : {},
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  },

  async Filters () {
    const Endpoint = API.Endpoint.Election.Filters
    const Data = {}
    return await API.RESTRequest(Endpoint, Data)
  },

  async Get (id, data = {}) {
    const Endpoint = API.Endpoint.Election.Get
    Endpoint.Path = Endpoint.Path.replace('{id}', id)
    const Data = {
      ...(defaultFields.length && { fields: defaultFields.join(',') }),
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  },

  async Create (data = {}) {
    const Endpoint = API.Endpoint.Election.Create
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  },

  async Update (id, data = {}) {
    const Endpoint = API.Endpoint.Election.Update
    Endpoint.Path = Endpoint.Path.replace('{id}', id)
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  },

  async UpdateOrCreate (data = {}) {
    return await data.id ? this.Update(data.id, data) : this.Create(data)
  },

  async Delete (id, data = {}) {
    const Endpoint = API.Endpoint.Election.Delete
    Endpoint.Path = Endpoint.Path.replace('{id}', id)
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  },

  async Action (id, action, data = {}) {
    const Endpoint = API.Endpoint.Election.Action
    const Data = {
      Id    : id,
      Action: action,
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  },

  async NotifyAll (id, type = 'email', data = {}) {
    const Endpoint = API.Endpoint.Election.Notification
    const Data = {
      Id  : id,
      Type: type,
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  },

  async NotifyMember (id, memberId, type = 'email', data = {}) {
    const Endpoint = API.Endpoint.Election.Notification
    const Data = {
      Id      : id,
      MemberId: memberId,
      Type    : type,
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  },

  async SendVotersPdf (id, committeeMemberId, data = {}) {
    const Endpoint = API.Endpoint.Election.SendVotersPdf
    const Data = {
      Id               : id,
      CommitteeMemberId: committeeMemberId,
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  },

  async SearchVoter (searchString, electionId, data = {}) {
    const Endpoint = API.Endpoint.Election.SearchVoter
    const Data = {
      Uid: searchString,
      Id : electionId,
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  },

  async GeneratePdf (electionId, data = {}) {
    const Endpoint = API.Endpoint.Election.GeneratePdf
    const Data = {
      Id: electionId,
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  },

  async CandidatesImport (data = {}, options = {}) {
    const Endpoint = API.Endpoint.Election.CandidatesImport
    const Data = data
    const Options = {
      ...{ timeout: 300000 },
      ...options
    }
    return await API.RESTRequest(Endpoint, Data, Options)
  },

  async CandidatesSettingsImport (data = {}, options = {}) {
    const Endpoint = API.Endpoint.Election.CandidatesSettingsImport
    const Data = data
    const Options = {
      ...{ timeout: 300000 },
      ...options
    }
    return await API.RESTRequest(Endpoint, Data, Options)
  },

  async ClearCache (electionId, data = {}) {
    const Endpoint = API.Endpoint.Election.ClearCache
    const Data = {
      Id: electionId,
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  },

  async SendResults (electionId, data = {}) {
    const Endpoint = API.Endpoint.Election.SendResults
    const Data = {
      Id: electionId,
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  }
}

const skipResource = false

const defaultSort = [skipResource ? 'id' : 'Id']

const defaultOrder = ['DESC']

const defaultFields = []
