<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :persistent="options.persistent"
    @keydown.esc="cancel"
  >
    <data-card
      :color="options.color"
      :colored-border="options.coloredBorder"
      :dark="options.dark"
      :dense="options.dense"
      :icon="options.icon"
      :sub-title="subTitle"
      :title="title"
      tile
    >
      <template #toolbar-append>
        <v-btn
          icon
          @click.native="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>

      <template #default>
        <v-card-text class="pa-4">
          <slot name="default">
            <div v-html="message" />
          </slot>

          <div
            v-if="options.selectTime"
            class="mt-2 pb-4 caption"
          >
            <div>{{ $t(`Bookings.Form.Fields.Time.Hint`) }}<span style="color: red">*</span></div>
            <v-menu
              ref="timeMenu"
              v-model="timeDialogVisible"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="selectedTime"
              max-width="290"
              min-width="290"
              offset-y
              transition="scale-transition"
            >
              <template #activator="{on, attrs}">
                <v-text-field
                  v-model="selectedTime"
                  clearable
                  color="black"
                  dense
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-time-picker
                v-if="timeDialogVisible"
                v-model="selectedTime"
                color="opelyellow"
                format="24hr"
                full-width
                @click:minute="$refs.timeMenu.save(selectedTime)"
              />
            </v-menu>
          </div>

          <div
            v-if="options.reason && options.reasons && options.reasons.items && options.reasons.items.length"
            class="mt-2 pb-4 caption"
          >
            <v-select
              v-model="selectedReason"
              :items="options.reasons.items"
              :label="options.reasons.label"
              clearable
              item-text="Name"
              item-value="Id"
              outlined
              prepend-inner-icon="mdi-comment-processing"
              return-object
              @change="reason = ''"
            />

            <v-text-field
              v-if="options.reason && selectedReason && selectedReason.Id === -1"
              v-model="reason"
              :label="options.reason.label"
              clearable
              hide-details
              outlined
              prepend-inner-icon="mdi-comment-processing"
            />
          </div>
          <div
            v-if="options.validate"
            class="mt-5 caption"
          >
            <span v-html="options.validate.text" />
            <v-text-field
              v-model="answer"
              :color="options.color"
              clearable
              filled
              hide-details
              prepend-inner-icon="mdi-lock"
              single-line
            />
          </div>
        </v-card-text>
      </template>

      <template #card-actions>
        <v-divider />
        <v-card-actions>
          <slot name="card-actions">
            <v-spacer />
            <v-btn
              v-if="options.button.no"
              color="grey"
              outlined
              tile
              @click.native="cancel"
            >
              {{ options.button.no.title }}
            </v-btn>

            <v-btn
              v-if="options.button.yes"
              :color="options.color"
              :dark="isNotValid ? false : options.dark"
              :disabled="isNotValid"
              depressed
              tile
              @click.native="confirm"
            >
              {{ options.button.yes.title }}
            </v-btn>
          </slot>
        </v-card-actions>
      </template>
    </data-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm-dialog ref="confirm"></confirm-dialog>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm-dialog ref="confirm"></confirm-dialog>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */

import DataCard from './DataCard'

export default {
  name      : 'ConfirmDialog',
  components: { DataCard },
  directives: {},
  mixins    : [],
  props     : [],
  data () {
    return {
      dialog           : false,
      resolve          : null,
      reject           : null,
      answer           : '',
      reason           : '',
      selectedReason   : null,
      selectedTime     : null,
      message          : '',
      title            : '',
      subTitle         : '',
      timeDialogVisible: false,
      defaultOptions   : {
        icon         : 'mdi-alert',
        color        : 'error',
        dense        : false,
        width        : 610,
        persistent   : true,
        dark         : false,
        coloredBorder: true,
        validate     : false,
        reason       : false,
        reasons      : null,
        selectTime   : false,
        button       : {
          no : { title: this.$t('Common.Button.No') },
          yes: { title: this.$t('Common.Button.Yes') }
        }
      },
      options: {}
    }
  },
  computed: {
    isNotValid () {
      const validate = (this.options.validate && String(this.answer).toUpperCase() !== String(this.options.validate.answer).toUpperCase())
      const time = this.options.selectTime && !this.selectedTime

      if (this.options.validate && this.options.selectTime) {
        return validate || time
      } else if (this.options.validate) {
        return validate
      } else if (this.options.selectTime) {
        return time
      }
      return false
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    open (title, subTitle, message, options = {}) {
      this.answer = ''
      this.reason = ''
      this.selectedReason = null
      this.selectedTime = options.startedTimeAt || ''
      this.selectTime = false
      this.timeDialogVisible = false
      this.title = title
      this.subTitle = subTitle
      this.message = message
      this.options = { ...this.defaultOptions, ...options }
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    confirm () {
      if (this.options.validate && String(this.answer).toUpperCase() !== String(this.options.validate.answer).toUpperCase()) return
      const reason = this.options.reason && this.selectedReason && this.selectedReason.Id > 0 ? this.selectedReason.Name : this.options.reason && this.reason.trim() ? this.reason.trim() : false
      const time = this.options.selectTime && this.selectedTime ? this.selectedTime : false

      if (this.options.reason && this.options.selectTime) {
        this.resolve(time)
      } else if (this.options.reason) {
        this.resolve(reason)
      } else if (this.options.selectTime) {
        this.resolve(time)
      } else {
        this.resolve(true)
      }

      this.dialog = false
    },

    cancel () {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>

<style scoped>
/deep/ .v-time-picker-title {
  color : #000000 !important;
}

/deep/ .v-time-picker-clock__item--active {
  color : #000000 !important;
}
</style>
