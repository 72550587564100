<template>
  <div class="pa-1 pa-sm-6">
    <v-img
      class="ma-2 ma-sm-6"
      contain
      height="80"
      position="center"
      src="/img/logo/logo-dark.png"
    />

    <v-card-title
      :class="{'h2': $vuetify.breakpoint.smAndUp, 'h3 font-weight-bold': $vuetify.breakpoint.xs,}"
      class="justify-center mb-5"
    >
      {{ $t('Users.ForgotPassword.Title') }}
    </v-card-title>

    <template v-if="successMessage">
      <v-card-subtitle class="h4 text-center my-3">
        {{ successMessage }}
      </v-card-subtitle>

      <v-card-actions class="d-block text-center px-4">
        <v-btn
          :large="$vuetify.breakpoint.smOnly"
          :to="{name: 'Login'}"
          :x-large="$vuetify.breakpoint.mdAndUp"
          class="px-4 px-sm-8 px-md-12"
          color="warning"
          depressed
          v-text="$t('Common.Button.Login')"
        />
      </v-card-actions>
    </template>

    <template v-else>
      <v-card-subtitle class="h4 text-left my-3">
        {{ $t('Users.ForgotPassword.SubTitle') }}
      </v-card-subtitle>

      <v-card-text>
        <v-form
          ref="form"
          v-model="isFormValid"
          @submit.prevent
        >
          <v-text-field
            v-model="model.Email"
            :disabled="isSubmittingData"
            :label="$t('Users.Login.Email')"
            :placeholder="$t('Users.Login.Email')"
            :rules="model.validator.vuetifyFormFieldRules('Email')"
            clearable
            outlined
            prepend-inner-icon="mdi-email-outline"
            type="email"
          />
        </v-form>

        <v-alert
          v-if="errorMessage"
          border="left"
          class="text-center"
          color="error"
          dense
          text
          type="error"
        >
          {{ errorMessage }}
        </v-alert>
      </v-card-text>

      <v-card-actions class="d-block text-right px-4">
        <v-btn
          :disabled="isSubmittingData"
          :large="$vuetify.breakpoint.smOnly"
          :to="{name: 'Login'}"
          :x-large="$vuetify.breakpoint.mdAndUp"
          class="px-4 px-sm-8 px-md-12"
          depressed
          plain
          v-text="$t('Common.Button.Cancel')"
        />

        <v-btn
          :disabled="!isFormValid || isSubmittingData"
          :large="$vuetify.breakpoint.smOnly"
          :loading="isSubmittingData"
          :x-large="$vuetify.breakpoint.mdAndUp"
          class="px-4 px-sm-8 px-md-12"
          color="brand-primary"
          depressed
          @click="onSubmitClick"
          v-text="$t('Common.Button.Continue')"
        />
      </v-card-actions>
    </template>
  </div>
</template>

<script>
import i18nRouteMeta       from '@/mixins/i18n/i18nRouteMeta'
import ForgotPasswordModel from '@/api/models/user/ForgotPasswordModel'
import ApiResponseEnum     from '@/api/enums/ApiResponseEnum'
import API                 from '@/api/Api'

export default {
  name      : 'ForgotPassword',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  data () {
    return {
      isFormValid     : false,
      isSubmittingData: false,
      successMessage  : '',
      errorMessage    : '',
      componentData   : new ForgotPasswordModel()
    }
  },
  computed: {
    model: {
      get () {
        return this.componentData
      },
      set (val) {
        this.componentData = val
      }
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    window.addEventListener('keydown', this.onKeyDown)
    if (this.$refs.form) this.$refs.form.reset()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  destroyed () {},
  methods: {
    onKeyDown (e) {
      if (e.keyCode === 13 && !this.isSubmittingData) {
        e.preventDefault()
        this.onSubmitClick()
      }
    },

    onSubmitClick () {
      if (this.validateForm()) {
        this.isSubmittingData = true
        this.successMessage = this.errorMessage = ''

        this.model.ForgotPassword()
          .then((response) => {
            if (API.isResponseSuccess(response)) {
              this.successMessage = response.data?.message || ''
            } else {
              this.errorMessage = response.data?.message || ''
            }
          })
          .catch((e) => {
            const status = e.response.status
            const message = e.response?.data?.message

            if (status === ApiResponseEnum.VALIDATOR_ERROR) this.errorMessage = message || this.$t('Common.Error.Generic')
          })
          .finally(() => {
            this.isSubmittingData = false
          })
      }
    },

    validateForm (deep = false) {
      this.isFormValid = true
      if (this.$refs?.form) this.isFormValid = this.$refs.form.validate()
      if (this.model?.validator) {
        this.isFormValid = (deep ? this.model.validate() : this.model.validator.validate())
        // eslint-disable-next-line no-console
        console.log(this.model.validator.constructor.name, this.model.errors)
      }
      return this.isFormValid
    }
  }
}

</script>

<style scoped>
/* Change Autocomplete styles in Chrome, Safari & Firefox*/
@-webkit-keyframes autofill {
  0%, 100% {
    color      : #FFFFFF;
    background : transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-delay     : 1s; /* Safari support - any positive time runs instantly */
  -webkit-animation-name      : autofill;
  -webkit-animation-fill-mode : both;
}
</style>
