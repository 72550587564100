import BaseModel       from '@/lib/data/model/BaseModel'
import MemberValidator from '@/api/validations/member/MemberValidator'
import MemberResource  from '@/api/resources/member/MemberResource'
import API             from '@/api/Api'

export default class MemberModel extends BaseModel {
  constructor (payload = {}, resource = MemberResource, validator = MemberValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['CreatedAt']
  }

  /* PROPERTIES */

  /* METHODS */

  async Get () {
    return await API.Resource.Member.Get(this.Id)
  }

  async Create () {
    return await API.Resource.Member.Create(this.clone())
  }

  async Update () {
    return await API.Resource.Member.Update(this.Id, this.clone())
  }

  async UpdateOrCreate () {
    return await API.Resource.Member.UpdateOrCreate(this.clone())
  }

  async Delete () {
    return await API.Resource.Member.Delete(this.Id)
  }
}
