import BaseValidator      from '@/lib/data/validator/BaseValidator'
import ValidatorTypesEnum from '@/lib/data/validator/ValidatorTypesEnum'

export default class ResetPasswordValidator extends BaseValidator {
  validatorRules = {
    Password: {
      required: true,
      type    : ValidatorTypesEnum.String,
      minLen  : 6
    },
    ConfirmPassword: {
      required: true,
      type    : ValidatorTypesEnum.String,
      minLen  : 6,
      maxLen  : 16,
      equals  : 'Password'
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
