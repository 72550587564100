<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <data-card
        v-if="model"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="false"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :title="`${model.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
        tile
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isFormSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="isFormValid"
            >
              <v-container fluid>
                <v-row dense>
                  <v-col cols="6">
                    <v-text-field
                      v-model="model.FirstName"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['FirstName']"
                      :hint="$t(`${parentComponentName}.Form.Fields.FirstName.Hint`)"
                      :label="$t(`${parentComponentName}.Form.Fields.FirstName.Label`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('FirstName')"
                      prepend-inner-icon="mdi-account-outline"
                    />
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      v-model="model.LastName"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['LastName']"
                      :hint="$t(`${parentComponentName}.Form.Fields.LastName.Hint`)"
                      :label="$t(`${parentComponentName}.Form.Fields.LastName.Label`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('LastName')"
                      prepend-inner-icon="mdi-account-outline"
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="6">
                    <v-text-field
                      v-model="model.Phone"
                      v-mask="'##########'"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Phone']"
                      :hint="$t(`${parentComponentName}.Form.Fields.Phone.Hint`)"
                      :label="$t(`${parentComponentName}.Form.Fields.Phone.Label`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Phone')"
                      prepend-inner-icon="mdi-phone-outline"
                    />
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      v-model="model.Email"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Email']"
                      :hint="$t(`${parentComponentName}.Form.Fields.Email.Hint`)"
                      :label="$t(`${parentComponentName}.Form.Fields.Email.Label`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Email')"
                      prepend-inner-icon="mdi-email-outline"
                      type="email"
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="6">
                    <v-text-field
                      v-model="model.Username"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Username']"
                      :hint="$t(`${parentComponentName}.Form.Fields.Username.Hint`)"
                      :label="$t(`${parentComponentName}.Form.Fields.Username.Label`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Username')"
                      autocomplete="new-username"
                      prepend-inner-icon="mdi-account-circle"
                    />
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      v-model="model.Password"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Password']"
                      :hint="$t(`${parentComponentName}.Form.Fields.Password.Hint`)"
                      :label="$t(`${parentComponentName}.Form.Fields.Password.Label`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Password')"
                      autocomplete="new-password"
                      prepend-inner-icon="mdi-lock"
                      type="password"
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="6">
                    <v-select
                      v-model="model.RoleId"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['RoleId']"
                      :hint="$t(`${parentComponentName}.Form.Fields.RoleId.Hint`)"
                      :items="roles"
                      :label="$t(`${parentComponentName}.Form.Fields.RoleId.Label`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('RoleId')"
                      item-text="Name"
                      item-value="Id"
                      prepend-inner-icon="mdi-account-group"
                    >
                      <template #selection="{item}">
                        <v-sheet
                          :color="item.Color"
                          class="mr-2"
                          height="18"
                          rounded
                          width="5"
                        />
                        {{ item.Name }}
                      </template>

                      <template #item="{on, attrs, item}">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-sheet
                            :color="item.Color"
                            class="mr-2"
                            height="40"
                            rounded
                            width="5"
                          />
                          <v-list-item-content>
                            <v-list-item-title v-text="item.Name" />
                            <v-list-item-subtitle v-text="item.Description" />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="6">
                    <v-select
                      v-model="model.Locale"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Locale']"
                      :hint="$t(`${parentComponentName}.Form.Fields.Locale.Hint`)"
                      :items="$i18n.languages"
                      :label="$t(`${parentComponentName}.Form.Fields.Locale.Label`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Locale')"
                      item-text="name"
                      item-value="locale"
                      prepend-inner-icon="mdi-translate"
                    >
                      <template #selection="{item}">
                        <img
                          :src="item.icon"
                          class="mr-2"
                          height="18"
                          width="18"
                        >

                        {{ item.name }}
                      </template>

                      <template #item="{on, attrs, item}">
                        <v-list-item
                          :disabled="!item.active"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <img
                            :src="item.icon"
                            class="mr-2"
                            height="32"
                            width="32"
                          >

                          <v-list-item-content>
                            <v-list-item-title v-text="item.name" />
                            <v-list-item-subtitle v-text="item.code" />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>

                <v-row
                  v-if="userIsSuperAdmin"
                  dense
                >
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="model.CompanyId"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['CompanyId']"
                      :hint="$t(`${parentComponentName}.Form.Fields.CompanyId.Hint`)"
                      :items="companies"
                      :label="$t(`${parentComponentName}.Form.Fields.CompanyId.Label`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('CompanyId')"
                      item-text="Name"
                      item-value="Id"
                      prepend-inner-icon="mdi-domain"
                    >
                      <template #item="{on, attrs, item}">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-content>
                            <v-list-item-title v-text="item.Name" />
                            <v-list-item-subtitle v-text="item.Description" />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="6">
                    <label class="grey--text caption">
                      {{ $t(`${ parentComponentName }.Form.Fields.Active.Label`) }}
                    </label>
                    <v-switch
                      v-model="model.Active"
                      :disabled="isReadOnly || user.Id === model.Id"
                      :label="model.Active ? $t(`Common.Button.Toggle.ActiveM`) : $t(`Common.Button.Toggle.InactiveM`)"
                      :readonly="isReadOnly || user.Id === model.Id"
                      class="mt-1"
                      color="success"
                      inset
                    />
                  </v-col>

                  <v-col cols="6">
                    <label class="grey--text caption">
                      {{ $t(`${ parentComponentName }.Form.Fields.ForceChangePassword.Label`) }}
                    </label>
                    <v-switch
                      v-model="model.ForceChangePassword"
                      :disabled="isReadOnly"
                      :label="model.ForceChangePassword ? $t(`Common.Button.Toggle.Active`) : $t(`Common.Button.Toggle.Inactive`)"
                      :readonly="isReadOnly"
                      class="mt-1"
                      color="success"
                      inset
                    />
                  </v-col>

                  <v-col
                    v-if="userCan('2FA', PermissionEnum.ACCESS) || userCan('2FA', PermissionEnum.MANAGE)"
                    cols="6"
                  >
                    <label class="grey--text caption">
                      {{ $t(`${ parentComponentName }.Form.Fields.TwoFA.Label`) }}
                    </label>
                    <v-switch
                      v-model="model.TwoFA"
                      :disabled="isReadOnly"
                      :label="model.TwoFA ? $t(`Common.Button.Toggle.Active`) : $t(`Common.Button.Toggle.Inactive`)"
                      :readonly="isReadOnly"
                      class="mt-1"
                      color="success"
                      inset
                    />
                  </v-col>

                  <v-col
                    v-if="userIsAdmin"
                    cols="6"
                  >
                    <label class="grey--text caption">
                      {{ $t(`${ parentComponentName }.Form.Fields.SingleLogin.Label`) }}
                    </label>
                    <v-switch
                      v-model="model.SingleLogin"
                      :disabled="isReadOnly"
                      :false-value="true"
                      :label="model.SingleLogin ? $t(`Common.Button.Toggle.InactiveFP`) : $t(`Common.Button.Toggle.ActiveFP`)"
                      :readonly="isReadOnly"
                      :true-value="false"
                      class="mt-1"
                      color="success"
                      inset
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-textarea
                      v-model="model.Notes"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Notes']"
                      :hint="$t(`${parentComponentName}.Form.Fields.Notes.Hint`)"
                      :label="$t(`${parentComponentName}.Form.Fields.Notes.Label`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Notes')"
                      prepend-inner-icon="mdi-message-reply-text-outline"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="!!showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ isBoolean(showGenericError) ? $t('Common.Error.Generic') : showGenericError }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              :disabled="isFormSaving"
              large
              plain
              tile
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              v-if="!isReadOnly"
              :disabled="isFormSaving"
              :loading="isFormSaving"
              color="success"
              depressed
              @click="saveFromData"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DataCard      from '@/theme/default/components/common/DataCard'
import dialogVisible from '@/mixins/dialog/dialogVisible'
import dialogData    from '@/mixins/dialog/dialogData'
import UserModel     from '@/api/models/user/UserModel'
import { isBoolean } from '@/lib/utils/type'

export default {
  name      : 'UserEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {
    roles: {
      type   : Array,
      default: () => []
    },
    companies: {
      type   : Array,
      default: () => []
    }
  },
  enums    : {},
  dataModel: UserModel,
  data () {
    return {}
  },
  computed: {},
  watch   : {
    isVisible (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.model.validator.validatorRules.CompanyId.required = this.userIsSuperAdmin
        })
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    ...{ isBoolean }
  }
}
</script>

<style scoped>

</style>
