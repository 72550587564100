import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class CompanyValidator extends BaseValidator {
  validatorRules = {
    Id: {
      required: false,
      type    : BaseValidator.Type.Number
    },
    Name: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2
    },
    Description: {
      required  : true,
      type      : BaseValidator.Type.String,
      betweenLen: [2, 255]
    },
    CompanyId: {
      required: false,
      type    : BaseValidator.Type.Number
    },
    CompanyName: {
      required: false,
      type    : BaseValidator.Type.String
    },
    EmailIdentity: {
      required: false,
      type    : BaseValidator.Type.Email,
      maxLen  : 96
    },
    SmsIdentity: {
      required: false,
      type    : BaseValidator.Type.String,
      maxLen  : 11
    },
    Active: {
      required: true,
      type    : BaseValidator.Type.Boolean
    },
    CreatedAt: {
      required: false,
      type    : BaseValidator.Type.undefined
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
