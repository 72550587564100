import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class MemberValidator extends BaseValidator {
  validatorRules = {
    Id: {
      required: false,
      type    : BaseValidator.Type.Number
    },
    Uid: {
      required: false,
      type    : BaseValidator.Type.String,
      maxLen  : 50
    },
    Corps: {
      required: false,
      type    : BaseValidator.Type.String
    },
    CompanyId: {
      required: true,
      type    : BaseValidator.Type.Number
    },
    FirstName: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2,
      maxLen  : 255
    },
    LastName: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2,
      maxLen  : 255
    },
    FathersName: {
      required: false,
      type    : BaseValidator.Type.String,
      minLen  : 2,
      maxLen  : 255
    },
    Phone: {
      required  : false,
      type      : BaseValidator.Type.String,
      startsWith: '69',
      length    : 10
    },
    Email: {
      required: false,
      type    : BaseValidator.Type.Email,
      maxLen  : 96
    },
    Active: {
      required: true,
      type    : BaseValidator.Type.Boolean
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
