import BaseResource from '@/lib/data/resource/BaseResource'

export default class ElectionResultsResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Participation = payload.Participation ?? ''
    this.Candidates = payload.Candidates ?? []
    this.Categories = payload.Categories || []
  }

  /* PROPERTIES */

  /* METHODS */
}
