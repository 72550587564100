<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :max-width="800"
      persistent
      scrollable
    >
      <data-card
        v-if="model"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :sub-title="!userIsSuperAdmin ? model.Description : ''"
        :title="`${$t('Common.Button.Copy')} ${$t(`${parentComponentName}.CopyDialog.Title`)}`"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isFormSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="isFormValid"
            >
              <v-container fluid>
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      v-model="model.CompanyIds"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['CompanyIds']"
                      :hint="$t(`${parentComponentName}.CopyDialog.Form.Fields.CompanyIds.Hint`)"
                      :items="companies"
                      :label="$t(`${parentComponentName}.CopyDialog.Form.Fields.CompanyIds.Label`)"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('CompanyIds')"
                      item-text="Name"
                      item-value="Id"
                      multiple
                      prepend-inner-icon="mdi-domain"
                      small-chips
                    >
                      <template #item="{on, attrs, item}">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-content>
                            <v-list-item-title v-text="item.Name" />
                            <v-list-item-subtitle v-text="item.Description" />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12">
                    <label class="grey--text caption">
                      {{ $t(`${ parentComponentName }.CopyDialog.Form.Fields.ForceUpdate.Label`) }}
                    </label>
                    <v-switch
                      v-model="model.ForceUpdate"
                      :disabled="isReadOnly"
                      :label="model.ForceUpdate ? $t(`Common.Button.Yes`) : $t(`Common.Button.No`)"
                      :readonly="isReadOnly"
                      class="mt-1"
                      color="success"
                      inset
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="!!showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ isBoolean(showGenericError) ? $t('Common.Error.Generic') : showGenericError }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              :disabled="isFormSaving"
              large
              plain
              tile
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              v-if="!isReadOnly"
              :disabled="isFormSaving"
              :loading="isFormSaving"
              color="success"
              depressed
              @click="saveFromData"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { themeUtils }    from '@/lib/utils'
import { isBoolean }     from '@/lib/utils/type'
import dialogVisible     from '@/mixins/dialog/dialogVisible'
import dialogData        from '@/mixins/dialog/dialogData'
import SettingsCopyModel from '@/api/models/setting/SettingsCopyModel'

const DataCard = () => themeUtils.importThemeComponent('components/common/DataCard')

export default {
  name      : 'SettingsCopyDialog',
  components: { DataCard },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {
    companies: {
      type   : Array,
      default: () => []
    }
  },
  enums    : {},
  dataModel: SettingsCopyModel,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    ...{ isBoolean }
  }
}
</script>

<style scoped>

</style>
