export default {
  Title: 'Κατηγορίες Ψηφοδελτίου',

  Table: {
    Button: {
      New: 'Νέα Κατηγορία Ψηφοδελτίου'
    },

    Headers: {
      Id         : '#',
      Name       : 'ΟΝΟΜΑ',
      Description: 'ΠΕΡΙΓΡΑΦΗ',
      Actions    : ''
    },

    Filter: {}
  },

  Confirm: {
    Delete: {
      Title   : 'Διαγραφή Κατηγορίας Ψηφοδελτίου {Id}',
      SubTitle: 'Επιβεβαίωση Διαγραφής Κατηγορίας Ψηφοδελτίου',
      Body    : 'Είστε σίγουροι ότι θέλετε προχωρήσετε στην οριστική διαγραφή της κατηγορίας ψηφοδελτίου {Id};'
    }
  },

  Form: {
    Title : 'Κατηγορίας Ψηφοδελτίου',
    Fields: {
      Name: {
        Label: 'Όνομα',
        Hint : ''
      },
      Description: {
        Label: 'Περιγραφή',
        Hint : ''
      }
    }
  }
}
