import API               from '@/api/Api'
import BaseModel         from '@/lib/data/model/BaseModel'
import { collect }       from '@/lib/utils/array'
import { singular }      from '@/lib/utils/string'
import { isPlainObject } from '@/lib/utils/type'

export default {
  name: 'DataTable',
  data () {
    return {
      dataTable: {
        loading: false,
        total  : 0,
        search : {
          terms          : '',
          debounceTimeout: null,
          enabled        : true
        },
        resize: {
          enabled        : true,
          debounceTimeout: null,
          bodyHeight     : undefined
        },
        options: {
          serverSideEnabled: true,
          multiSort        : true,
          mustSort         : false,
          itemsPerPage     : 10,
          page             : 1,
          sortBy           : [],
          sortDesc         : []
        },
        filter                     : {},
        filterDebounceTimeout      : null,
        customFilter               : {},
        payload                    : {},
        customFilterDebounceTimeout: null,
        headers                    : [],
        data                       : []
      }
    }
  },

  computed: {
    filterData () {
      const filtersLangObj = this.$te(`${ this.$options.name }.Table.Filter`) ? this.$t(`${ this.$options.name }.Table.Filter`) : {}
      const filtersTableObj = isPlainObject(this.dataTable.filter) && isPlainObject(this.dataTable.customFilter) ? { ...this.dataTable.filter, ...this.dataTable.customFilter } : {}
      const filtersLangKeys = isPlainObject(filtersLangObj) ? Object.keys(filtersLangObj) : []
      const filtersTableKeys = isPlainObject(filtersTableObj) ? Object.keys(filtersTableObj) : []

      const retVal = filtersLangKeys.filter(key => filtersTableKeys.includes(key)).reduce((obj, key) => {
        obj[key] = filtersLangObj[key]
        return obj
      }, {})

      return retVal
    }
  },

  watch: {
    'dataTable.search.terms': {
      handler (newVal, oldVal) {
        if (!this.dataTable.options.serverSideEnabled) return
        if (String(newVal).trim() !== String(oldVal).trim() && (String(newVal).trim().length >= 3 || String(newVal).trim().length === 0)) {
          this.dataTable.options.page = 1
          clearTimeout(this.dataTable.search.debounceTimeout)
          this.dataTable.search.debounceTimeout = setTimeout(() => {
            this.getData()
          }, 500)
        }
      },
      deep: false
    },
    'dataTable.filter': {
      handler () {
        if (!this.dataTable.options.serverSideEnabled) return
        this.dataTable.options.page = 1
        clearTimeout(this.dataTable.filterDebounceTimeout)
        this.dataTable.filterDebounceTimeout = setTimeout(() => {
          this.getData()
        }, 500)
      },
      deep: true
    },
    'dataTable.customFilter': {
      handler () {
        if (!this.dataTable.options.serverSideEnabled) return
        this.dataTable.options.page = 1
        clearTimeout(this.dataTable.customFilterDebounceTimeout)
        this.dataTable.customFilterDebounceTimeout = setTimeout(() => {
          this.getData()
        }, 500)
      },
      deep: true
    },
    'dataTable.options': {
      handler () {
        if (!this.dataTable.options.serverSideEnabled) return
        this.getData()
      },
      deep: true
    }
  },

  methods: {
    requestData () {
      const dt = JSON.parse(JSON.stringify(this.dataTable))
      Object.keys(dt?.filter || {}).forEach(key => {
        if (dt.filter[key] === 'null') dt.filter[key] = null
      })
      return {
        ...(dt?.options?.page && { page: dt?.options?.page }),
        ...(dt?.options?.itemsPerPage && { limit: dt?.options?.itemsPerPage }),
        ...(dt?.options?.sortBy?.length && { sort: dt?.options?.sortBy }),
        ...(dt?.options?.sortDesc?.length && { order: dt?.options?.sortDesc?.map(v => v === true ? 'DESC' : 'ASC') }),
        ...(dt?.options?.fields && { fields: dt?.options?.fields }),
        ...(dt?.search?.terms?.trim() && { search: dt?.search?.terms?.trim() }),
        ...(dt?.filter && { filter: dt?.filter }),
        ...(dt?.customFilter && { customFilter: dt?.customFilter }),
        ...(dt?.payload && (dt?.payload || {}))
      }
    },

    getData () {
      const name = singular(this.$options.name, 1) || this.$options.name
      if (API.Resource.hasOwnProperty(name)) {
        this.dataTable.loading = true
        API.Resource[name].List(this.requestData())
          .then(response => {
            if (API.isResponseSuccess(response)) {
              this.dataTable.total = response.data?.data?.total || 0
              this.dataTable.data = collect(response.data?.data?.items || [], this.$options.dataModel || BaseModel)
            }
          })
          .catch(e => { })
          .finally(() => {
            this.dataTable.loading = false
          })
      } else {
        // eslint-disable-next-line no-console
        console.warn('dataTable MIXIN ::: You must implement "getData" function in your component!')
      }
    },

    calcTableBodyHeight () {
      if (!this.dataTable.resize.enabled) return
      const app = document.getElementsByClassName('v-application--wrap')[0]
      if (!app) return

      const table = app.getElementsByClassName('v-data-table')[0]
      const tableCard = table ? table.parentElement : null
      if (!tableCard) return

      const footer = app.getElementsByTagName('footer')[0]
      const footerHeight = footer ? footer.offsetHeight : 0

      const tableFooter = tableCard.getElementsByClassName('v-data-footer')[0]
      const tableFooterHeight = tableFooter ? tableFooter.offsetHeight : 0

      const cardTitle = tableCard.getElementsByClassName('v-card__title')[0]
      const cardTitleHeight = cardTitle ? cardTitle.offsetHeight : 0

      const toolbar = app.getElementsByTagName('header')[0]
      const toolbarHeight = toolbar ? toolbar.offsetHeight : 64

      this.dataTable.resize.bodyHeight = `calc(100vh - ${ toolbarHeight + cardTitleHeight + tableFooterHeight + footerHeight + 26 }px)`
    },

    resizeDebounced () {
      if (!this.dataTable.resize.enabled) return
      clearTimeout(this.dataTable.resize.debounceTimeout)
      this.dataTable.resize.debounceTimeout = setTimeout(this.calcTableBodyHeight, 350)
    }
  },

  created () {
    this.calcTableBodyHeight()
    window.addEventListener('resize', this.resizeDebounced)
    if (typeof this.getFilterData === 'function') this.getFilterData()
  },

  mounted () {
    this.calcTableBodyHeight()
  },

  updated () {
    this.calcTableBodyHeight()
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.resizeDebounced)
  }

}
