<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      persistent
      scrollable
    >
      <data-card
        v-if="model"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :title="`${model.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isFormSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="isFormValid"
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="model.Name"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Name']"
                      :hint="$te(`${parentComponentName}.Form.Fields.Name.Hint`) ? $t(`${parentComponentName}.Form.Fields.Name.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.Name.Label`) ? $t(`${parentComponentName}.Form.Fields.Name.Label`) : 'Name'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Name')"
                      @focusin="resetServerErrorMessage('Name')"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="model.Description"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Description']"
                      :hint="$te(`${parentComponentName}.Form.Fields.Description.Hint`) ? $t(`${parentComponentName}.Form.Fields.Description.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.Description.Label`) ? $t(`${parentComponentName}.Form.Fields.Description.Label`) : 'Description'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Description')"
                      @focusin="resetServerErrorMessage('Description')"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="!!showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ isBoolean(showGenericError) ? $t('Common.Error.Generic') : showGenericError }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              :disabled="isFormSaving"
              large
              plain
              tile
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              v-if="!isReadOnly"
              :disabled="isFormSaving"
              :loading="isFormSaving"
              color="success"
              depressed
              @click="saveFromData"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { themeUtils }        from '@/lib/utils'
import { isBoolean }         from '@/lib/utils/type'
import dialogVisible         from '@/mixins/dialog/dialogVisible'
import dialogData            from '@/mixins/dialog/dialogData'
import ElectionCategoryModel from '@/api/models/election/ElectionCategoryModel'

const DataCard = () => themeUtils.importThemeComponent('components/common/DataCard')

export default {
  name      : 'ElectionCategoryEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {},
  enums     : {},
  dataModel : ElectionCategoryModel,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    ...{ isBoolean }
  }
}
</script>

<style scoped>

</style>
