import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class SettingValidator extends BaseValidator {
  validatorRules = {
    Id: {
      required: false,
      type    : BaseValidator.Type.Number
    },
    CompanyId: {
      required: true,
      type    : BaseValidator.Type.Number
    },
    Group: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2
    },
    Item: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2
    },
    Description: {
      required  : true,
      type      : BaseValidator.Type.String,
      betweenLen: [2, 255]
    },
    Value: {
      required   : true,
      type       : BaseValidator.Type.Array,
      ValueMinLen: (val) => val.length > 0
    },
    Global: {
      required: true,
      type    : BaseValidator.Type.Boolean
    },
    Admin: {
      required: true,
      type    : BaseValidator.Type.Boolean
    },
    ApiGroup: {
      required      : true,
      type          : BaseValidator.Type.Array,
      ApiGroupMinLen: (val) => val.length > 0
    },
    Active: {
      required: true,
      type    : BaseValidator.Type.Boolean
    }
  }

  validatorMessages = {
    el: {
      ValueMinLen   : 'Πρέπει να προσθέσετε τουλάχιστον 1 επιλογή ρυθμίσεων',
      ApiGroupMinLen: 'Πρέπει να επιλέξετε τουλάχιστον 1'
    },
    en: {
      ValueMinLen   : 'You must add minimum 1 option setting',
      ApiGroupMinLen: 'You must select minimum 1'
    }
  }

  constructor (data = {}) {
    super(data)
  }
}
