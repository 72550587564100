import i18n                        from '@/lang/lang'
import { AES, Base64AES, HmacMD5 } from '@/lib/crypto/crypto'

export default async (to, from, next, injection) => {
  const vue = injection || window.Vue
  const electionSessionKey = HmacMD5.hash('election')
  const id = Base64AES.decrypt(to.params?.id)
  const election = to.params?.election || AES.decrypt(vue.$sessionStorage.get(electionSessionKey, null))
  const toDashboard = {
    replace: true,
    name   : 'Dashboard',
    params : { lang: i18n.locale },
    query  : {}
  }

  if (!to.params?.id || !id) {
    next(toDashboard)
  } else {
    if (election) {
      vue.$sessionStorage.set(electionSessionKey, AES.encrypt(election))
      to.params.election = election
      next()
    } else {
      next(toDashboard)
    }
  }
}
