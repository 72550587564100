import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class ElectionSettingValidator extends BaseValidator {
  validatorRules = {
    Id: {
      required: false,
      type    : BaseValidator.Type.Number
    },
    Participation: {
      required: false,
      type    : BaseValidator.Type.Number
    },
    'Candidates.*.CandidateId': {
      required: true,
      type    : BaseValidator.Type.Number
    },
    'Candidates.*.Value': {
      required: true,
      type    : BaseValidator.Type.Numeric
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
