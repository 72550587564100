import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class SettingsCopyValidator extends BaseValidator {
  validatorRules = {
    CompanyIds: {
      required: true,
      type    : BaseValidator.Type.Array,
      minLen  : 1
    },
    SettingIds: {
      required: true,
      type    : BaseValidator.Type.Array,
      minLen  : 1
    }
  }

  validatorMessages = {
    el: {
      minLen: 'Πρέπει να επιλέξετε τουλάχιστον {rule}'
    },
    en: {
      minLen: 'You must select minimum {rule}'
    }
  }

  constructor (data = {}) {
    super(data)
  }
}
