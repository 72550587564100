import { API } from '@/api/Api'

export default {
  async List (data = {}) {
    const Endpoint = API.Endpoint.Company.List
    const Data = {
      page        : 1,
      limit       : 10,
      sort        : defaultSort,
      order       : defaultOrder,
      fields      : defaultFields,
      skipResource: skipResource,
      search      : '',
      filter      : {},
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  },

  async DropDownList (data = {}) {
    const Endpoint = API.Endpoint.Company.List
    const Data = {
      page        : 1,
      limit       : 10000,
      sort        : defaultSort,
      order       : defaultOrder,
      fields      : ['Id', 'Name', 'Description'],
      skipResource: skipResource,
      search      : '',
      filter      : {},
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  },

  async Get (id, data = {}) {
    const Endpoint = API.Endpoint.Company.Get
    Endpoint.Path = Endpoint.Path.replace('{id}', id)
    const Data = {
      ...(defaultFields.length && { fields: defaultFields.join(',') }),
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  },

  async Create (data = {}) {
    const Endpoint = API.Endpoint.Company.Create
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  },

  async Update (id, data = {}) {
    const Endpoint = API.Endpoint.Company.Update
    Endpoint.Path = Endpoint.Path.replace('{id}', id)
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  },

  async UpdateOrCreate (data = {}) {
    return await data.id ? this.Update(data.id, data) : this.Create(data)
  },

  async Delete (id, data = {}) {
    const Endpoint = API.Endpoint.Company.Delete
    Endpoint.Path = Endpoint.Path.replace('{id}', id)
    const Data = data
    return await API.RESTRequest(Endpoint, Data)
  }
}

const skipResource = false

const defaultSort = [skipResource ? 'id' : 'Id']

const defaultOrder = ['DESC']

const defaultFields = []
