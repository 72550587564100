<template>
  <v-app>
    <v-main class="grey darken-3">
      <v-container
        class="fill-height"
        fluid
      >
        <v-row>
          <v-col
            class="text-center"
            cols="12"
          >
            <v-card
              class="elevation-12 d-inline-block"
              dark
              min-width="280"
              width="650"
            >
              <slot />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <app-footer />
  </v-app>
</template>

<script>

import AppFooter from '@/theme/default/components/app/AppFooter'

export default {
  name      : 'LoginLayout',
  components: { AppFooter },
  mixins    : [],
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods : {}
}

</script>

<style scoped>

</style>
