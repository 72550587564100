<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1024"
      persistent
      scrollable
    >
      <data-card
        v-if="model"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :title="`${model.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isFormSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="isFormValid"
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="model.Participation"
                      v-mask="'###'"
                      :clearable="!isReadOnly"
                      :disabled="isReadOnly"
                      :error-messages="serverErrorMessages['Participation']"
                      :hint="$te(`${parentComponentName}.Form.Fields.Participation.Hint`) ? $t(`${parentComponentName}.Form.Fields.Participation.Hint`) : ''"
                      :label="$te(`${parentComponentName}.Form.Fields.Participation.Label`) ? $t(`${parentComponentName}.Form.Fields.Participation.Label`) : 'Participation'"
                      :readonly="isReadOnly"
                      :rules="model.validator.vuetifyFormFieldRules('Participation')"
                      append-icon="mdi-percent"
                      filled
                      flat
                      hide-spin-buttons
                      max="100"
                      min="0"
                      type="number"
                      @focusin="resetServerErrorMessage('Participation')"
                    />
                  </v-col>
                </v-row>

                <template v-for="(category) in model.Categories">
                  <v-row
                    :key="`category-${category.Id}`"
                    dense
                  >
                    <v-col cols="12">
                      <v-toolbar
                        :color="!$vuetify.theme.dark ? 'grey lighten-3' : ''"
                        dense
                        flat
                      >
                        <v-toolbar-title class="subtitle-2">
                          {{ category.Name }}
                        </v-toolbar-title>

                        <v-spacer />

                        <v-btn
                          v-if="!isReadOnly"
                          color="primary"
                          depressed
                          fab
                          x-small
                          @click="addCandidate(category.Id)"
                        >
                          <v-icon>
                            mdi mdi-plus
                          </v-icon>
                        </v-btn>
                      </v-toolbar>
                    </v-col>

                    <v-col cols="12">
                      <v-simple-table dense>
                        <template #default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                {{
                                  $te(`${parentComponentName}.Form.Fields.Committee.Name`) ? $t(`${parentComponentName}.Form.Fields.Committee.Name`) : 'Name'
                                }}
                              </th>
                              <th class="text-left">
                                {{
                                  $te(`${parentComponentName}.Form.Fields.Committee.Percent`) ? $t(`${parentComponentName}.Form.Fields.Committee.Percent`) : 'Percent'
                                }}
                              </th>
                              <th
                                v-if="!isReadOnly"
                                class="text-right"
                              />
                            </tr>
                          </thead>
                          <tbody>
                            <template
                              v-for="(item, index) in model.Candidates.filter(candidate => category.Id === candidate.CategoryId)"
                            >
                              <tr :key="index">
                                <td class="text-no-wrap">
                                  <v-autocomplete
                                    v-model="item.CandidateId"
                                    :clearable="!isReadOnly"
                                    :disabled="isReadOnly"
                                    :item-text="(item) => `${item.Uid} - ${item.LastName} ${item.FirstName} ${item.FathersName ? `του ${item.FathersName}` : ''} ${item.Corps ? `(${item.Corps})` : '' }`"
                                    :items="category.Candidates"
                                    :label="$te(`${parentComponentName}.Form.Fields.CandidateId.Label`) ? $t(`${parentComponentName}.Form.Fields.CandidateId.Label`) : 'Candidate'"
                                    :readonly="isReadOnly"
                                    :rules="model.validator.vuetifyFormFieldRules(`Candidates.${index}.CandidateId`, `Candidates.*.CandidateId`)"
                                    class="mt-4"
                                    dense
                                    filled
                                    flat
                                    item-value="CandidateId"
                                    single-line
                                  />
                                </td>

                                <td class="text-no-wrap">
                                  <v-text-field
                                    v-model="item.Value"
                                    v-mask="'###'"
                                    :clearable="!isReadOnly"
                                    :disabled="isReadOnly"
                                    :placeholder="'Percent'"
                                    :readonly="isReadOnly"
                                    :rules="model.validator.vuetifyFormFieldRules(`Candidates.${index}.Value`, `Candidates.*.Value`)"
                                    append-icon="mdi-percent"
                                    class="mt-4"
                                    dense
                                    filled
                                    flat
                                    hide-spin-buttons
                                    max="100"
                                    min="0"
                                    single-line
                                    type="number"
                                  />
                                </td>

                                <td class="text-right text-no-wrap">
                                  <template v-if="!isReadOnly">
                                    <v-tooltip
                                      :open-delay="650"
                                      color="red"
                                      left
                                    >
                                      <template #activator="{on}">
                                        <v-btn
                                          color="red"
                                          icon
                                          small
                                          v-on="on"
                                          @click.stop="removeCandidate(item.CandidateId)"
                                        >
                                          <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                      </template>
                                      <span v-text="$t('Common.Button.Delete')" />
                                    </v-tooltip>
                                  </template>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </template>
              </v-container>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="!!showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ isBoolean(showGenericError) ? $t('Common.Error.Generic') : showGenericError }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              :disabled="isFormSaving"
              large
              plain
              tile
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>

            <v-btn
              v-if="!isReadOnly"
              color="success"
              depressed
              @click="importData"
              v-text="$t('Common.Button.Import')"
            />

            <v-btn
              v-if="!isReadOnly"
              :disabled="isFormSaving"
              :loading="isFormSaving"
              color="success"
              depressed
              @click="saveFromData"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>

    <election-settings-import-dialog
      :election="model"
      :visible.sync="importDialogVisible"
    />
  </v-row>
</template>

<script>
import { themeUtils } from '@/lib/utils'
import { isBoolean } from '@/lib/utils/type'
import dialogVisible from '@/mixins/dialog/dialogVisible'
import dialogData from '@/mixins/dialog/dialogData'
import ElectionSettingModel from '@/api/models/election/ElectionSettingModel'
import ElectionSettingsImportDialog from '@/theme/default/views/election/ElectionSettingsImportDialog.vue'

const DataCard = () => themeUtils.importThemeComponent('components/common/DataCard')

export default {
  name      : 'ElectionSettingsDialog',
  components: {
    ElectionSettingsImportDialog,
    DataCard
  },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {},
  enums     : {},
  dataModel : ElectionSettingModel,
  data () {
    return {
      isFormSaving       : false,
      importDialogVisible: false
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    ...{ isBoolean },

    importData () {
      this.importDialogVisible = true
    },

    addCandidate (categoryId) {
      this.model.Candidates.push(
          {
            Id         : null,
            CandidateId: null,
            CategoryId : categoryId,
            Value      : ''
          }
      )
    },

    removeCandidate (candidateId) {
      const index = this.model.Candidates.findIndex(candidate => candidate.CandidateId === candidateId)

      this.model.Candidates.splice(index, 1)
    }
  }
}
</script>

<style scoped>

</style>
