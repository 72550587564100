import { API } from '@/api/Api'

export default {
  async List (data = {}) {
    const Endpoint = API.Endpoint.ElectionMember.List
    const Data = {
      page        : 1,
      limit       : 10,
      sort        : defaultSort,
      order       : defaultOrder,
      fields      : defaultFields,
      skipResource: skipResource,
      search      : '',
      filter      : {},
      ...data
    }
    return await API.RESTRequest(Endpoint, Data)
  }

}

const skipResource = false

const defaultSort = [skipResource ? 'id' : 'Id']

const defaultOrder = ['DESC']

const defaultFields = []
