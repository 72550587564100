<template>
  <div
    :style="{cursor: to || $listeners.click ? 'pointer' : ''}"
    @click="routeAction"
    v-on="$listeners"
  >
    <v-alert
      :border="border"
      :class="[`text-${align}`]"
      :color="color"
      :colored-border="coloredBorder"
      :dark="dark"
      :elevation="elevation"
      :height="height"
      :icon="icon"
      :max-height="maxHeight"
      :max-width="maxWidth"
      :min-height="minHeight"
      :min-width="minWidth"
      :outlined="outlined"
      :prominent="prominent"
      :text="text"
      :tile="tile"
      :width="width"
      class="ma-0"
    >
      <slot>
        <slot name="title">
          <div
            v-if="title"
            :class="['body-2', titleClass]"
            v-text="title"
          />
        </slot>

        <slot name="subTitle">
          <div
            v-if="subTitle"
            :class="[coloredBorder ? `${color}--text` : '', {'title': prominent}, subTitleClass]"
            v-text="subTitle"
          />
        </slot>
      </slot>
    </v-alert>
  </div>
</template>

<script>
export default {
  name      : 'MiniStatsIconCard2',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    prominent: {
      type   : Boolean,
      default: true
    },
    dark: {
      type: Boolean
    },
    tile: {
      type: Boolean
    },
    elevation: {
      type   : String,
      default: '2'
    },
    border: {
      type   : String,
      default: 'left'
    },
    coloredBorder: {
      type   : Boolean,
      default: true
    },
    text: {
      type   : Boolean,
      default: false
    },
    outlined: {
      type   : Boolean,
      default: false
    },
    align: {
      type   : String,
      default: 'center'
    },
    title: {
      type   : [String, Number],
      default: ''
    },
    titleClass: {
      type   : String,
      default: ''
    },
    subTitle: {
      type   : [String, Number],
      default: ''
    },
    subTitleClass: {
      type   : String,
      default: ''
    },
    color: {
      type   : String,
      default: 'pink'
    },
    icon: {
      type   : String,
      default: ''
    },
    iconTitle: {
      type   : String,
      default: ''
    },
    iconSize: {
      type   : String,
      default: '32'
    },
    iconBackgroundColor: {
      type   : String,
      default: 'white'
    },
    to: {
      type   : [String, Object],
      default: ''
    },
    height: {
      type   : [String, Number],
      default: 80
    },
    minHeight: {
      type   : [String, Number],
      default: undefined
    },
    maxHeight: {
      type   : [String, Number],
      default: undefined
    },
    width: {
      type   : [String, Number],
      default: undefined
    },
    minWidth: {
      type   : [String, Number],
      default: undefined
    },
    maxWidth: {
      type   : [String, Number],
      default: undefined
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  activated () {},
  deactivated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    routeAction () {
      if (this.to) {
        this.$router.push(this.to)
      }
    }
  }
}
</script>

<style scoped>

</style>
