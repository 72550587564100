import BaseModel                  from '@/lib/data/model/BaseModel'
import ChangePasswordValidator    from '@/api/validations/user/ChangePasswordValidator'
import ChangePasswordResource     from '@/api/resources/user/ChangePasswordResource'
import ChangePasswordSaveResource from '@/api/resources/user/ChangePasswordSaveResource'
import API                        from '@/api/Api'

export default class ChangePasswordModel extends BaseModel {
  constructor (payload = {}, resource = ChangePasswordResource, validator = ChangePasswordValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
  async ChangePassword () {
    return await API.Resource.User.ChangePassword(this.clone(false, ChangePasswordSaveResource))
  }
}
