export default {

  Title: 'Μέλη',

  Table: {
    Button: {
      New: 'Νέο Μέλος'
    },

    Headers: {
      Id         : '#',
      CompanyName: 'ΕΝΩΣΗ',
      Uid        : 'ΑΡ. ΤΑΥΤΟΤΗΤΑΣ',
      Corps      : 'ΚΛΑΔΟΣ',
      FirstName  : 'ΟΝΟΜΑ',
      LastName   : 'ΕΠΩΝΥΜΟ',
      FathersName: 'ΠΑΤΡΩΝΥΜΟ',
      Email      : 'EMAIL',
      Phone      : 'ΤΗΛΕΦΩΝΟ',
      Active     : 'ΕΝΕΡΓΟ',
      CreatedAt  : 'ΕΓΓΡΑΦΗ',
      Actions    : ''
    },

    Filter: {
      Active: {
        Type       : 'v-btn-toggle',
        Label      : 'Μέλη',
        PlaceHolder: 'Όλα τα Μέλη',
        ItemText   : 'Name',
        ItemValue  : 'Value',
        Items      : [
          {
            Value: 'null',
            Name : 'Όλα'
          },
          {
            Value: 1,
            Name : 'Ενεργά'
          },
          {
            Value: 0,
            Name : 'Ανενεργά'
          }
        ]
      },

      CompanyId: {
        Type       : 'v-select',
        Label      : 'Ενώσεις',
        PlaceHolder: 'Όλες οι Ενώσεις',
        ItemText   : 'Name',
        ItemValue  : 'Id',
        ItemDesc   : 'Description',
        Items      : []
      }
    }
  },

  Confirm: {
    Delete: {
      Title   : 'Διαγραφή Μέλους {Id}',
      SubTitle: 'Επιβεβαίωση Διαγραφής Μέλους',
      Body    : 'Είστε σίγουροι ότι θέλετε προχωρήσετε στην οριστική διαγραφή του μέλους {Id};'
    }
  },

  Import: {
    Title   : 'Εισαγωγή Μελών',
    SubTitle: 'Εισαγωγή Excel Μελών'
  },

  Form: {
    Title : 'Μέλους',
    Fields: {
      Active: {
        Label: 'Μέλος',
        Hint : ''
      },
      Uid: {
        Label: 'Αρ. Ταυτότητας',
        Hint : ''
      },
      Corps: {
        Label: 'Κλάδος',
        Hint : '',
        Items: ['Σ.Ξ.', 'Π.Α.', 'Π.Ν.']
      },
      Email: {
        Label: 'Email',
        Hint : ''
      },
      FirstName: {
        Label: 'Όνομα',
        Hint : ''
      },
      LastName: {
        Label: 'Επώνυμο',
        Hint : ''
      },
      FathersName: {
        Label: 'Πατρώνυμο',
        Hint : ''
      },
      CompanyId: {
        Label: 'Ένωση',
        Hint : ''
      },
      Phone: {
        Label: 'Τηλέφωνο',
        Hint : ''
      }
    }
  }
}
