import BaseModel     from '@/lib/data/model/BaseModel'
import VoterResource from '@/api/resources/member/VoterResource'
import API           from '@/api/Api'

export default class VoterModel extends BaseModel {
  constructor (payload = {}, resource = VoterResource, validator = null) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */

  async UpdateOrCreate () {
    return await API.Resource.Election.SearchVoter(this.Uid, this.ElectionId, {
      VoterId : this.VoterId,
      MemberId: this.Id
    })
  }
}
