import BaseModel              from '@/lib/data/model/BaseModel'
import ElectionMemberResource from '@/api/resources/election/ElectionMemberResource'

export default class ElectionMemberModel extends BaseModel {
  constructor (payload = {}, resource = ElectionMemberResource, validator = null) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
