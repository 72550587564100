import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class SettingItemValidator extends BaseValidator {
  validatorRules = {
    Key: {
      required: true,
      type    : BaseValidator.Type.String
    },
    Value: {
      required: false
    },
    Active: {
      required: true,
      type    : BaseValidator.Type.Boolean
    },
    Readonly: {
      required: true,
      type    : BaseValidator.Type.Boolean
    },
    Visible: {
      required: true,
      type    : BaseValidator.Type.Boolean
    },
    Cast: {
      required: true,
      type    : BaseValidator.Type.String
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
