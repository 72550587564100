import BaseModel     from '@/lib/data/model/BaseModel'
import UserValidator from '@/api/validations/user/UserValidator'
import UserResource  from '@/api/resources/user/UserResource'
import API           from '@/api/Api'
import { AES }       from '@/lib/crypto/crypto'

export default class UserModel extends BaseModel {
  constructor (payload = {}, resource = UserResource, validator = UserValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['Admin', 'CreatedAt', 'RoleColor', 'RoleName', 'FullName', 'DisplayName', 'CompanyName', 'Permissions']
  }

  /* PROPERTIES */

  /* METHODS */
  async Get () {
    return await API.Resource.User.Get(this.Id)
  }

  async Create () {
    const user = this.clone()
    if (user.Password) user.Password = AES.encrypt(user.Password)
    return await API.Resource.User.Create(user)
  }

  async Update () {
    const user = this.clone()
    if (user.Password) user.Password = AES.encrypt(user.Password)
    return await API.Resource.User.Update(this.Id, user)
  }

  async UpdateOrCreate () {
    const user = this.clone()
    if (user.Password) user.Password = AES.encrypt(user.Password)
    return await API.Resource.User.UpdateOrCreate(user)
  }

  async Delete () {
    return await API.Resource.User.Delete(this.Id)
  }
}
